import React, { useRef, useState } from 'react';
import './clientLayout.scss';
import { Layout, Menu, Row, Col, Button, Select, Steps, Modal, ConfigProvider } from 'antd';
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import BookingSummary from './../../pages/bookingSummary/bookingSummary';
import { SearchOutlined, RedoOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Stepper } from '@progress/kendo-react-layout';
import { useGlobalState } from '../../services/state';
import { Appointment, Customer, Location, Vehicle, ServiceAdvisor, BookingData } from './../../models/appModels';
import { ClientService, CommonService, StorageService, Constant } from '../../services/services'

const { Header, Content, Footer, Sider } = Layout;
const { confirm } = Modal;
const { Option } = Select;

ClientService.registerIntercepter()

const MainLayout = (props: any) => {
    const [current, setCurrent] = React.useState<number>(0)
    const { t, i18n } = useTranslation();
    const [appointmentDetailData, setAppointmentDetailData] = useGlobalState('appointmentDetailData');
    const [serviceDetailData, setServiceDetailData] = useGlobalState('serviceDetailData');
    const [jobLineData, setJobLineData] = useGlobalState('jobLineData');
    const [customerDetailData, setCustomerDetailData] = useGlobalState('customerDetailData');
    const [vehicleDetailData, setVehicleDetailData] = useGlobalState('vehicleDetailData');
    const [isInit] = useGlobalState('isInit');
    const childRef = useRef<any>();
    const [useAnimate, setUseAnimate] = React.useState<boolean>(false)
    const [, setReservationToken] = useGlobalState('reservationToken');
    const [, setbookingData] = useGlobalState('bookingData');
    const [dealerStyle] = useGlobalState('dealerStyle');
    const [, setOwnedVehiclesData] = useGlobalState('ownedVehiclesData');
    const [logoUrl, setLogoUrl] = useGlobalState('logoUrl');
    const [, setBookingSearchData] = useGlobalState('bookingSearchData');
    const [languages] = useGlobalState('languageList');
    const [locations, setLocations] = useGlobalState('locationList');
    const [dealerships, setDealerships] = useGlobalState('dealerships');
    const [antLanguage, setAntLanguage] = useGlobalState('antLanguage');
    const [currentLanguage, setCurrentLanguage] = useState<string>();
    const [prefix, setPrefix] = useGlobalState('prefix');

    const summaryRef = useRef<any>();

    React.useEffect(() => {
        if (dealerStyle) {
            CommonService.setDealerStyle(dealerStyle)
        }

        if(!prefix){
            ClientService.getPrefix().then(res => {
                setPrefix(res.data)
            })
            .catch(e => {})
        }

        window.onbeforeunload = (e: any) => {
            e.preventDefault()
            e.returnValue = '';
        };
        if (!isInit) {
            props.history.push(Constant.pages.DealershipSelection);
        }
        if (!logoUrl) {
            let logoUrlStorage = StorageService.getLogoUrl()
            if (logoUrlStorage) {
                setLogoUrl(logoUrlStorage)
            }
        }
    }, []);

    React.useEffect(() => {
        CommonService.setDealerStyle(dealerStyle)
    }, [dealerStyle])

    const iconsWithLabel = [
        { icon: 'k-i-osb k-i-customer', label: t("steps.your_detail") },
        { icon: 'k-i-osb k-i-vehicle', label: t("steps.your_vehicle") },
        { icon: 'k-i-osb k-i-service', label: t("steps.service_details") },
        { icon: 'k-i-osb k-i-booking', label: t("steps.booking_details") },
        { icon: 'k-i-osb k-i-summary', label: t("steps.confirm_details") }
    ];

    const changeStep = (value: number, doSubmit: boolean = false, saveBeforeChange: boolean = false) => {

        if (current != Constant.bookingStep.bookingDetail && current != Constant.bookingStep.confirmDetail
            && current != Constant.bookingStep.vehicleCustomerSearch) {
            if (saveBeforeChange && childRef.current) {
                childRef.current.saveData()
            }
        }

        if (current == Constant.bookingStep.bookingDetail) {
            if (childRef.current) {
                childRef.current.saveData(value)
                return
            }
        }

        doChangeStep(value, doSubmit)
    };

    const doChangeStep = (value: number, doSubmit: boolean = false) => {
        setUseAnimate(false)

        if (current == Constant.bookingStep.confirmDetail) {
            setUseAnimate(true)
        }

        setCurrent(value);
        if (value == Constant.bookingStep.customerDetail) {
            props.history.push(Constant.pages.CustomerDetail, {
                doSubmit: doSubmit
            });
        } else if (value == Constant.bookingStep.vehicleDetail) {
            props.history.push(Constant.pages.VehicleDetail, {
                doSubmit: doSubmit
            });
        }
        else if (value == Constant.bookingStep.bookingDetail) {
            props.history.push(Constant.pages.BookingDetail, {
                doSubmit: doSubmit
            });
        }
        else if (value == Constant.bookingStep.serviceDetail) {
            props.history.push(Constant.pages.ServiceDetail, {
                doSubmit: doSubmit
            });
        }
        else if (value == Constant.bookingStep.confirmDetail) {
            setUseAnimate(true)
            props.history.push(Constant.pages.ConfirmDetail);
        }
        else if (value == Constant.bookingStep.bookingSuccess) {
            props.history.push(Constant.pages.BookingSuccess);
        }
        if (summaryRef.current) {
            summaryRef.current.resetCollapse()
        }
    }

    const resetDataBooking = () => {
        setAppointmentDetailData({} as Appointment)
        setServiceDetailData({
            location: serviceDetailData.location,
            serviceAdvisor: {} as ServiceAdvisor
        });
        setJobLineData([]);
        // setCustomerDetailData({
        //     Id: customerDetailData.Id,
        //     CustomerType: customerDetailData.CustomerType,
        //     OrganisationId: customerDetailData.OrganisationId,
        //     OrganisationName: customerDetailData.OrganisationName,
        //     OrganisationPhone: customerDetailData.OrganisationPhone
        // } as Customer)
        setJobLineData([]);
        setCustomerDetailData({} as Customer)
        setVehicleDetailData({} as Vehicle)
        if (current == 0 && childRef.current && childRef.current.resetForm()) {
            childRef.current.resetForm()
        }
        setReservationToken("")
        setbookingData({
            Booking: {},
            IsFromDMS: false
        } as BookingData)
        setOwnedVehiclesData([])
        setBookingSearchData({})
    }

    const formHasValue = (checkCurrent: boolean = false) => {
        if (childRef.current && childRef.current.formHasValue())
            return true
        if (!checkCurrent) {
            if (hasCustomerDetails() ||
                hasVehicleDetails() ||
                hasServiceDetails() ||
                hasBookingDetails()) {
                return true
            }
        }
        return false
    }

    const hasCustomerDetails = () => {
        if (!customerDetailData)
            return false
        if (customerDetailData.FirstName ||
            customerDetailData.LastName ||
            (customerDetailData.StreetAddress && (
                customerDetailData.StreetAddress.StreetLine1 ||
                customerDetailData.StreetAddress.Suburb ||
                customerDetailData.StreetAddress.State ||
                customerDetailData.StreetAddress.PostCode
            )) ||
            customerDetailData.Mobile ||
            customerDetailData.Email)
            return true
        return false
    }

    const hasVehicleDetails = () => {
        if (!vehicleDetailData)
            return false
        if (vehicleDetailData.RegoNo ||
            (vehicleDetailData.Make && vehicleDetailData.Make.MakeId) ||
            (vehicleDetailData.Model && vehicleDetailData.Model.Code))
            return true
        return false
    }

    const hasServiceDetails = () => {
        if ((serviceDetailData && serviceDetailData.location && serviceDetailData.location.LocationCode) ||
            jobLineData.length > 0)
            return true
        return false
    }

    const hasBookingDetails = () => {
        if (appointmentDetailData && (appointmentDetailData.DropOffTime || appointmentDetailData.PickUpTime || appointmentDetailData.Note))
            return true
        return false
    }


    const goToSearchBooking = (isGoToLandingPage: boolean = false, skipValidation: boolean = false) => {
        if (!skipValidation && formHasValue()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.confirm_cancel_booking"),
                okText: t("common.ok"),
                cancelText: t("common.cancel"),
                onOk() {
                    resetDataBooking()
                    if (isGoToLandingPage) {
                        props.history.push(Constant.pages.DealershipSelection);
                    }
                    else {
                        props.history.push(Constant.pages.SearchBooking);
                    }
                    setCurrent(0)
                },
                onCancel() {
                }

            });
        }
        else {
            resetDataBooking()
            if (isGoToLandingPage) {
                props.history.push(Constant.pages.DealershipSelection);
            }
            else {
                props.history.push(Constant.pages.SearchBooking);
            }
            setCurrent(0)
        }
    }

    const restartBooking = () => {
        if (formHasValue()) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("common.confirm_reset_booking"),
                okText: t("common.ok"),
                cancelText: t("common.cancel"),
                onOk() {
                    resetDataBooking()
                    setCurrent(0)
                    props.history.push(Constant.pages.CustomerDetail);

                },
                onCancel() {
                }

            });
        }
        else {
            resetDataBooking()
            setCurrent(0)
            props.history.push(Constant.pages.CustomerDetail);
        }

    }

    const isConfirmDetailsPage = () => {
        return current == Constant.bookingStep.confirmDetail ? true : false
    }

    const getLogoUrl = () => {
        let imageType = logoUrl.substring("data:image/".length, logoUrl.indexOf(";base64"))
        if (imageType)
            return logoUrl
        return `${logoUrl}?${(new Date()).toString()}`
    }

    const onLanguageChange = (value: any) => {
        i18n.changeLanguage(value)
        setCurrentLanguage(value)
        StorageService.setLanguage(value)

        let language = Constant.languageCodes.find(l => l.Code == value)
        if (language) {
            setAntLanguage(language.AntdLanguage)
        }

        let newLocation = CommonService.updateLocationDealershipGlobalName(locations, value)
        setLocations(newLocation)

        let newDealership = CommonService.updateDealershipGlobalName(dealerships, value)
        setDealerships(newDealership)
    }

    return (

        <Layout className="layout">
            <Header className="layout-header background-background">
                <div className="layout-info">
                    <div className="logo" onClick={() => { goToSearchBooking(true) }}>
                        <img src={logoUrl ? getLogoUrl() : process.env.PUBLIC_URL + '/TiTan_Logo_Main.png'} />
                    </div>
                    <p style={{ textAlign: 'center' }} className="app_title" dangerouslySetInnerHTML={{ __html: t("common.app_title") }}></p>

                    <div className="btn-group">
                        {languages && languages.length > 1 &&
                            <Select value={currentLanguage} dropdownClassName="wrap-text-dropdown uppercase-text" defaultValue={languages[0]} className="w-65 m-r-5 custom-dropdown uppercase-text" onChange={onLanguageChange}>
                                {
                                    languages.map((n, index) => <Option key={index} title={n} value={n}>{n}</Option>)
                                }
                            </Select>
                        }

                        {props.showStepper &&
                            <span>
                                <Button className="btn secondary-btn" key="2" icon={<SearchOutlined />}
                                    onClick={() => { goToSearchBooking() }}><span className="btn-description">{t("common.search")}</span></Button>
                                <Button className="btn m-l-5 secondary-btn" key="1" icon={<RedoOutlined />}
                                    onClick={restartBooking}><span className="btn-description">{t("common.reset")}</span></Button>
                            </span>
                        }
                    </div>
                </div>
                {props.showStepper &&
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 3 }}>
                            <Stepper {...props} value={current} onChange={e => { changeStep(e.value, false, true) }} items={iconsWithLabel} />
                        </Col>
                    </Row>
                }
            </Header>

            <Layout className={`layout-container background-background ${props.showStepper ? "" : "hide-stepper"}`}>
                <Row>
                    {!isConfirmDetailsPage() &&
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: isConfirmDetailsPage() ? 0 : (props.showStepper ? 17 : 24) }}>
                            <div className="content">
                                <ConfigProvider locale={antLanguage}>
                                    {
                                        React.cloneElement(props.children,
                                            {
                                                changeStep: changeStep,
                                                ref: (node: any) => { childRef.current = node },
                                                doChangeStep: doChangeStep,
                                                backToMain: goToSearchBooking
                                            })
                                    }
                                </ConfigProvider>
                            </div>
                        </Col>
                    }
                    {props.showStepper &&
                        <Col className="summary-column" lg={{ span: isConfirmDetailsPage() ? 16 : 7, offset: isConfirmDetailsPage() ? 3 : 0 }} md={{ span: isConfirmDetailsPage() ? 18 : 0, offset: isConfirmDetailsPage() ? 3 : 0 }} xs={{ span: isConfirmDetailsPage() ? 24 : 0 }}>
                            <BookingSummary ref={summaryRef} isConfirmDetailsPage={isConfirmDetailsPage()} className={useAnimate ? (isConfirmDetailsPage() ? "summary-expand" : "summary-collapse") : ""}></BookingSummary>
                        </Col>
                    }
                    {isConfirmDetailsPage() &&
                        <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 3 }}>
                            <div className="content summary-column">
                                <ConfigProvider locale={antLanguage}>
                                    {
                                        React.cloneElement(props.children,
                                            {
                                                changeStep: changeStep,
                                                ref: (node: any) => { childRef.current = node },
                                                doChangeStep: doChangeStep,
                                                backToMain: goToSearchBooking
                                            })
                                    }
                                </ConfigProvider>
                            </div>
                        </Col>
                    }

                </Row>
            </Layout>
        </Layout>

    )

}

export default MainLayout