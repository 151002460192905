import React, { forwardRef, useEffect, useState } from 'react';
import './bookingSearch.scss'
import { Button, Row, Col, Form, Input } from 'antd';
import { Constant } from '../../services/services'
import { useTranslation } from 'react-i18next';
import { ClientService } from './../../services/clientService'
import { CommonService as commonService } from './../../services/commonService'
import { useGlobalState } from '../../services/state';
import BookingSelection from './bookingSelection/bookingSelection';
import { ServiceBooking, BookingData, Customer } from '../../models/appModels';
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { OTPVerification } from '../../components/otpVerification/otpVerification'
import KeyPressOnlyNumeric from '../../pages/utils/validateInput';
import CustomerSelection from './../customerDetail/customerSelection/customerSelection';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const formLayout = {
    xl: { span: 8, offset: 8 },
    lg: { span: 10, offset: 7 },
    md: { span: 12, offset: 6 },
    sm: { span: 16, offset: 4 },
    xs: { span: 22, offset: 1 }
};

const btnLayout = {
    xs: { span: 24 },
    lg: { span: 17 },
    md: { span: 18 },
    xl: { span: 16 }
};

const BookingSearch = forwardRef((props: any, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [, setAppointmentDetailData] = useGlobalState('appointmentDetailData');
    const [serviceDetailData, setServiceDetailData] = useGlobalState('serviceDetailData');
    const [, setJobLineData] = useGlobalState('jobLineData');
    const [, setCustomerDetailData] = useGlobalState('customerDetailData');
    const [, setVehicleDetailData] = useGlobalState('vehicleDetailData');
    const [, setOriginalVehicleMake] = useGlobalState('originalVehicleMake');
    const [bookingData, setbookingData] = useGlobalState('bookingData');
    const [, setBookingSearchDataRequest] = useGlobalState('bookingSearchDataRequest');
    const [visibleModal, setVisibleModal] = useState(false);
    const [bookingList, setBookingList] = useState<ServiceBooking[]>([]);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [dealershipId, setDealershipId] = useGlobalState('dealershipId');
    const [, setOwnedVehiclesData] = useGlobalState('ownedVehiclesData');
    const [, setBookingSearchData] = useGlobalState('bookingSearchData');
    const [makeList, setMakeList] = useGlobalState('makeList');
    const [customerType] = useGlobalState('customerType');
    const [, setCustomerPhoneNumber] = useGlobalState('customerPhoneNumber');
    const [visibleOTPVerification, setVisibleOTPVerification] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [visibleCustomerModal, setVisibleCustomerModal] = useState(false);
    const [customerSearchList, setCustomerSearchList] = useState<Customer[]>([]);
    const [customerDetail, setCustomerDetail] = useGlobalState('customerDetailData');

    useEffect(() => {
        if (makeList == null || makeList.length == 0) {
            ClientService.getMakesByCountry(process.env.REACT_APP_COUNTRY_CODE)
                .finally(() => { })
                .then((result) => {
                    if (result) setMakeList(result.data);
                })
                .catch((error) => {
                    commonService.handleErrorResponse(error);
                });
            return () => {
                ClientService.cancelRequest();
            };
        }
    }, []);

    function searchData() {
        const values = form.getFieldsValue();
        showLoading();
        setCustomerPhoneNumber(values.mobileNumber);
        setBookingSearchDataRequest({
            CardRegistration: values.cardRegistration,
            MobileNumber: values.mobileNumber
        });
        ClientService.searchBooking(values.cardRegistration, values.mobileNumber, dealershipId, customerType === Constant.customerTypes.Organization)
            .finally(() => dismissLoading())
            .then(res => {
                if (res) {
                    if ((res.AllBookings && res.AllBookings.length > 0) || (res.Booking && res.Booking.Appointment && (res.Booking.Id || res.Booking.DmsBookingKey))) {
                        let bookings: ServiceBooking[] = [];
                        if (res.AllBookings && res.AllBookings.length > 0) {
                            bookings = res.AllBookings;
                            if (makeList && makeList.length > 0) {
                                bookings = res.AllBookings.filter(b => b.Vehicle && (!b.Vehicle.MakeId || makeList.some(s => s.Id == b.Vehicle.MakeId)));
                            }
                        }
                        if (bookings && bookings.length == 0) {
                            if (!commonService.validateVehicleMakeSearch(res.Booking, makeList)) {
                                return;
                            }
                            bookings = [res.Booking];
                        }
                        setBookingList(bookings)
                        setVisibleModal(true);
                    }
                    else {
                        if (commonService.validateVehicleMakeSearch(res.Booking, makeList)) {

                            if (res.Customers && res.Customers.length > 0) {
                                setupData(res, false);
                                setCustomerSearchList(res.Customers)
                                setVisibleCustomerModal(true);
                            } else {
                                setupData(res);
                            }
                        }
                    }
                }
            })
            .catch(error => commonService.handleErrorResponse(error));
    }

    function setupData(data: BookingData, isNextStep: boolean = true) {
        if (data) {
            if (!data.Booking.Id && !data.Booking.DmsBookingId) {
                if (!data.Booking.Customer && !data.Booking.Vehicle)
                    commonService.presentToast('info', t("customer_search.empty_booking_mes"), t("common.notification_header"))
                else
                    commonService.presentToast('info', t("customer_search.matched_vehicle_customer_mes"), t("common.notification_header"))
            }
            if (data.Booking.Appointment) {
                if (data.Booking.Appointment.DropOffTime) {
                    data.Booking.Appointment.BookingDate = data.Booking.Appointment.DropOffTime
                    data.Booking.Appointment.DropOffTime = new Date(data.Booking.Appointment.DropOffTime)
                }
                if (data.Booking.Appointment.PickUpTime) {
                    data.Booking.Appointment.PickUpTime = new Date(data.Booking.Appointment.PickUpTime)
                }
                data.Booking.Appointment.Note = data.Booking.CustomerComment
                setAppointmentDetailData(data.Booking.Appointment)
                if (data.Booking.Appointment.Location) {
                    setServiceDetailData({
                        location: data.Booking.Appointment.Location,
                        serviceAdvisor: data.Booking.ServiceAdvisor
                    })
                    if (!dealershipId) {
                        setDealershipId(data.Booking.Appointment.Location.DealershipId)
                    }
                }
            }
            if (data.Booking.Jobs)
                setJobLineData(data.Booking.Jobs)
            if (data.Booking.Customer) {
                data.Booking.Customer.CustomerType = customerType ? customerType : Constant.customerTypes.Person;
                setCustomerDetailData(data.Booking.Customer)
            }
            else {
                let values = form.getFieldsValue();
                if (customerType === Constant.customerTypes.Organization) {
                    let obj = {
                        CustomerType: customerType,
                        Mobile: values.mobileNumber
                    }
                    setCustomerDetailData(obj as Customer)
                } else {
                    setCustomerDetailData({ Mobile: values.mobileNumber, CustomerType: customerType } as Customer)
                }
            }
            if (data.Booking.Vehicle) {
                if (data.Booking.Customer &&
                    ((data.Booking.Customer.Id > 0 && data.Booking.Vehicle.OwnerCustomerId > 0 && data.Booking.Customer.Id == data.Booking.Vehicle.OwnerCustomerId) ||
                        (data.Booking.Vehicle.OwnerCustomer && data.Booking.Customer.DmsCustomerKey > 0 && data.Booking.Vehicle.OwnerCustomer.DmsCustomerKey > 0 && data.Booking.Customer.DmsCustomerKey == data.Booking.Vehicle.OwnerCustomer.DmsCustomerKey))) {
                    data.Booking.Vehicle.IsCarOwner = true
                }
                setVehicleDetailData(data.Booking.Vehicle)
                setOriginalVehicleMake(data.Booking.Vehicle.MakeId)
                setBookingSearchData({})
            }
            else {
                let values = form.getFieldsValue();
                if (values.cardRegistration) {
                    let isVin = values.cardRegistration.length == Constant.requiredVinLength ? true : false
                    setBookingSearchData({
                        IsVin: isVin,
                        CardRegistration: values.cardRegistration
                    })
                }
            }
            if (data.Booking.OwnedVehicles) {
                let ownedVehicles = data.Booking.OwnedVehicles.filter(v => commonService.validateVehicleMake(v, makeList))
                setOwnedVehiclesData(ownedVehicles)
            }
            setbookingData(data)
        }
        if (isNextStep) {
            props.changeStep(Constant.bookingStep.customerDetail)
        }
    }

    const handleCancel = (objectData?: ServiceBooking) => {
        if (objectData && bookingData) {
            bookingData.Booking = objectData
            setupData(bookingData)
        }
        setVisibleModal(false)
    };

    const handleCancelOTPVerification = () => {
        setVisibleOTPVerification(false);
    }

    const handleNewBooking = (objectData: ServiceBooking) => {
        if (objectData) {
            if (objectData.Customer) {
                objectData.Customer.CustomerType = customerType ? customerType : Constant.customerTypes.Person;
                setCustomerDetailData(objectData.Customer)
            }
            else {
                let values = form.getFieldsValue();
                if (customerType === Constant.customerTypes.Organization) {
                    let obj = {
                        CustomerType: customerType,
                        ContactCustomer: { Mobile: values.mobileNumber }
                    }
                    setCustomerDetailData(obj as Customer)
                } else {
                    setCustomerDetailData({ Mobile: values.mobileNumber, CustomerType: customerType } as Customer)
                }
            }
            if (objectData.Vehicle) {
                if (objectData.Customer && objectData.Customer.Id == objectData.Vehicle.OwnerCustomerId) {
                    objectData.Vehicle.IsCarOwner = true
                }
                setVehicleDetailData(objectData.Vehicle)
                setOriginalVehicleMake(objectData.Vehicle.MakeId)
                setBookingSearchData({})
            }
            else {
                let values = form.getFieldsValue();
                if (values.cardRegistration) {
                    let isVin = values.cardRegistration.length == Constant.requiredVinLength ? true : false
                    setBookingSearchData({
                        IsVin: isVin,
                        CardRegistration: values.cardRegistration
                    })
                }
            }
            if (objectData.OwnedVehicles) {
                setOwnedVehiclesData(objectData.OwnedVehicles)
            }
        }
        setVisibleModal(false)
        props.changeStep(Constant.bookingStep.customerDetail)
    }
    const submit = () => {
        form.submit();
    }
    const openOTPValidation = () => {
        setVisibleOTPVerification(true);
    }
    const closeCustomerSelection = (obj?: Customer) => {
        setVisibleCustomerModal(false)
        setCustomerSearchList([])
        if (obj) {
            setCustomerDetail(obj)
        }
        props.changeStep(Constant.bookingStep.customerDetail)
    }
    return (
        <>
            <Form {...layout} form={form} name="control-hooks" onFinish={openOTPValidation}>
                <Row>
                    <Col {...formLayout}>
                        {serviceDetailData && serviceDetailData.location &&
                            <h3 className='location-container'>{`${serviceDetailData.location.DealershipName}-${serviceDetailData.location.LocationName}`}</h3>
                        }
                        <Form.Item name="cardRegistration" label={t("vehicle_detail.car_registration_or_vin")}
                            labelAlign="left" colon={false}
                            normalize={value => commonService.excludeSpecialCharactersInput(value)}
                            rules={[
                                {
                                    required: true,
                                    message: t("vehicle_detail.car_registration_or_vin_required")
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="mobileNumber" label={t("customer_search.mobile_number")} labelAlign="left" colon={false}
                            rules={[
                                { required: true, message: t("customer_search.mobile_number_require_mes") },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (process.env.REACT_APP_RESTRICT_PHONE_LENGTH && Number(process.env.REACT_APP_RESTRICT_PHONE_LENGTH) > 0 && value && value.length !== Number(process.env.REACT_APP_RESTRICT_PHONE_LENGTH)) {
                                            return Promise.reject(t("customer_search.mobile_number_invalid", { length: process.env.REACT_APP_RESTRICT_PHONE_LENGTH }));
                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}
                            normalize={value => commonService.excludeSpecialCharactersInput(value)}
                        >
                            <Input maxLength={process.env.REACT_APP_RESTRICT_PHONE_LENGTH ? Number(process.env.REACT_APP_RESTRICT_PHONE_LENGTH) : undefined} onKeyPress={KeyPressOnlyNumeric} onChange={event => { setPhoneNumber(event.target.value) }} />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    visibleModal &&
                    <BookingSelection handleCancel={handleCancel} bookingList={bookingList} handleNewBooking={handleNewBooking}></BookingSelection>
                }
                {
                    visibleOTPVerification &&
                    <OTPVerification phoneNumber={phoneNumber} dealershipId={dealershipId} handleCancel={handleCancelOTPVerification} handleSuccess={searchData} />
                }
                {
                    visibleCustomerModal &&
                    <CustomerSelection handleCancel={closeCustomerSelection} objectData={customerSearchList} ></CustomerSelection>
                }
            </Form>
            <Row className='custom-footer'>
                <Col {...btnLayout} className="text-right">
                    <Button type="primary" className="btn" onClick={submit}>
                        {t("common.next_label")}
                    </Button>
                </Col>
            </Row>
        </>
    );
})

export default BookingSearch