import React, { useState, useEffect } from 'react';
import './bookingManagement.scss';
import { Table, Empty, Row, Col, List, Card, Spin, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from './../utils/loadingCompt/loadingContext'
import {
    Constant,
    CommonService,
    BookingManagementService,
    AlignType
} from '../../services/services'
import { BookingStatusCell, DeleteCommandCell, GetTableConfigs, GetColumnTextFilterProps, ConfirmCommandCell, StartCommandCell, GetColumnDateRangeFilterProps, EditCommandCell } from './../utils/gridColumn/gridColumn'
import { ServiceBooking, CancellationCode, ConfirmBookingRequest } from '../../models/appModels';

import CancelBooking from './cancelBooking/cancelBooking'
import ServiceBookingDetail from './serviceBookingDetail/serviceBookingDetail'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroller';
import { setTimeout } from 'timers';
import {
    LoadingOutlined
} from '@ant-design/icons';
import { ActiveBookingIcon, CompleteBookingIcon, CancelBookingIcon, PendingBookingIcon } from './../../components/icons/icon'
import HistoryBookingDetail from './historyBookingDetail/historyBookingDetail';
import UpdateBooking from './updateBooking/updateBooking';
import moment from 'moment'

const { confirm } = Modal;

const BookingManagement = (props: any) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState<any>({
        pageNumber: 1,
        pageSize: Constant.pageSize,
        sort: "",
        isLoadMore: true,
        showLoading: false
    })
    const [totalItems, setTotalItems] = useState(0)
    const [bookingData, setBookingData] = useState<ServiceBooking[]>([])
    const [cancellationCodes, setCancellationCodes] = React.useState<CancellationCode[]>([]);
    const [selectedObject, setSelectedObject] = useState<ServiceBooking | null>();
    const [visibleCancelBookingModal, setVisibleCancelBookingModal] = useState(false);
    const [visibleServiceModal, setVisibleServiceModal] = useState(false);
    const [isShowOrderSummaryLoading, setIsShowOrderSummaryLoading] = useState(false);
    const [bookingSummaryData, setBookingSummaryData] = useState<any>({})
    const [visibleHistoryModal, setVisibleHistoryModal] = useState(false);
    const [visibleEditBookingModal, setVisibleEditBookingModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(Constant.bookingStatusCode.Pending);
    const [allowModify, setAllowModify] = useState<boolean>(false)

    const getColumns = () => {
        let columns = [
            {
                title: t('booking_management.header_booking_id'),
                dataIndex: 'IdPrefix',
                key: 'IdPrefix',
                sorter: true,
                ellipsis: true,
                width: 70
            },
            {
                title: t('booking_management.header_rego'),
                dataIndex: 'RegoNo',
                render: (value: any, record: any) => (
                    <label>{record && record.Vehicle ? record.Vehicle.RegoNo : ''}</label>
                ),
                sorter: true,
                ellipsis: true,
                width: 220,
                ...GetColumnTextFilterProps()
            },
            {
                title: t('booking_management.header_customer'),
                dataIndex: 'CustomerName',
                render: (value: any, record: any) => {
                    if (record && record.Customer && record.Customer.IsOrganisation) {
                        return (<label>{record && record.Driver ? record.Driver.Name : ''}</label>);
                    } else {
                        return (<label>{record && record.Customer ? record.Customer.Name : ''}</label>);
                    }
                },
                sorter: true,
                ellipsis: true,
                width: 180,
                ...GetColumnTextFilterProps()
            },
            {
                title: t('booking_management.header_service'),
                dataIndex: 'Service',
                ellipsis: true,
                render: (value: any, record: any) => (
                    <a onClick={() => viewService(record)}>{t('booking_management.view')}</a>
                ),
                width: 90,
                ...GetColumnTextFilterProps()
            },
            {
                title: t('booking_management.header_dms_booking_id'),
                dataIndex: 'DmsBookingId',
                key: 'DmsBookingId',
                sorter: true,
                ellipsis: true,
                width: 180,
                render: (value: any, record: any) => (
                    <label>{record.DmsBookingId == "-1" ? "" : record.DmsBookingId}</label>
                ),
                ...GetColumnTextFilterProps()
            },
            {
                title: t('booking_management.header_status'),
                dataIndex: 'StatusCode',
                key: 'StatusCode',
                sorter: true,
                render: (value: any, record: any) => (
                    <BookingStatusCell data={record.StatusCode}
                        editFunction={cancelBooking} />
                ),
                align: 'center' as AlignType,
                width: 120
            },
            {
                title: t('booking_management.header_creation_date'),
                dataIndex: 'CreationTimestamp',
                key: 'CreationTimestamp',
                render: (value: any, record: any) => (
                    <label>{CommonService.getDateString(record.CreationTimestamp) + ' ' + CommonService.getTimeString(record.CreationTimestamp)}</label>
                ),
                sorter: true,
                ellipsis: true,
                width: 180,
                ...GetColumnDateRangeFilterProps()
            },
            {
                title: t('booking_management.booking_date'),
                dataIndex: 'DropOffTime',
                key: 'DropOffTime',
                render: (value: any, record: any) => (
                    <label>{record && record.Appointment && record.Appointment.DropOffTime ? (CommonService.getDateString(record.Appointment.DropOffTime) + ' ' + CommonService.getTimeString(record.Appointment.DropOffTime)) : ''}</label>
                ),
                sorter: true,
                ellipsis: true,
                width: 180,
                ...GetColumnDateRangeFilterProps()
            },
            {
                title: t('booking_management.header_location'),
                dataIndex: 'Location',
                render: (value: any, record: any) => (
                    <label>{record && record.Appointment && record.Appointment.Location ?
                        record.Appointment.Location.LocationCode + ' - ' + record.Appointment.Location.LocationName : ''}</label>
                ),
                sorter: true,
                ellipsis: true,
                width: 200,
                ...GetColumnTextFilterProps()
            },
            {
                title: t('booking_management.history'),
                dataIndex: 'History',
                ellipsis: true,
                render: (value: any, record: any) => (
                    <a onClick={() => viewHistory(record)}>{t('booking_management.view')}</a>
                ),
                width: 80,
                align: 'center' as AlignType
            },

        ];

        if (allowModify) {
            let obj: any = {
                title: t('common.action'),
                key: 'Id',
                dataIndex: 'Id',
                render: (value: any, record: any) => (
                    <div>
                        {record.StatusCode == Constant.bookingStatusCode.Pending && <>
                            <EditCommandCell data={record} editFunction={() => editBookingData(record)} />
                            <ConfirmCommandCell tooltip={t('common.confirm')} data={record} processFunction={confirmBooking} />
                        </>

                        }
                        {(record.StatusCode == Constant.bookingStatusCode.Active || record.StatusCode == Constant.bookingStatusCode.Pending) && <DeleteCommandCell tooltip={t('common.cancel')} data={record} deleteFunction={cancelBooking} />}
                    </div>
                ),
                align: 'center' as AlignType,
                width: 100
            }
            columns.push(obj)
        }
        return columns
    }

    const getServiceBookingSummary = (filterList: any[]) => {
        setIsShowOrderSummaryLoading(true)
        BookingManagementService.getServiceBookingSummary(filterList)
            .finally(() => {
                setIsShowOrderSummaryLoading(false)
            })
            .then(res => {
                setBookingSummaryData(res.data)
            }).catch(error => {
                CommonService.handleErrorResponse(error)
            })

    }

    useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.BookingManagement))
        getBookings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)

        BookingManagementService.getCancellationCode().then(data => {
            setCancellationCodes(data.data)
        }).catch(error => {
            CommonService.handleErrorResponse(error)
        })
        getServiceBookingSummary(gridConfigOptions.filters)
        return () => {
            BookingManagementService.cancelRequest()
        }
    }, [])

    const cancelBooking = (data: any) => {
        setSelectedObject(data)
        setVisibleCancelBookingModal(true)
    }

    const confirmBooking = (data: any) => {
        var today = new Date();
        if (data.Appointment && data.Appointment.DropOffTime) {
            var bookingDay = new Date(data.Appointment.DropOffTime);
            if (bookingDay < today) {
                CommonService.presentToast('warning', t("booking_management.invalid_booking_date_message"), t("common.notification_header"))
                return
            }
        }

        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("common.confirm_booking"),
            okText: t("common.ok"),
            cancelText: t("common.cancel"),
            onOk() {
                let request = { ServiceBookingId: data.Id } as ConfirmBookingRequest

                showLoading();
                BookingManagementService.confirmBooking(request).then(result => {
                    getBookings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true);
                    getServiceBookingSummary(gridConfigOptions.filters);
                    CommonService.presentToast('success', t("confirmation_detail.booking_confirm_successful"), t("common.notification_header"))
                }).catch(error => {
                    CommonService.handleErrorResponse(error)
                })
                    .finally(() => {
                        dismissLoading()
                    })
            },
            onCancel() { }
        });
    }

    const viewService = (data: any) => {
        setSelectedObject(data)
        setVisibleServiceModal(true)
    }

    const getBookings = (pageNumber: number, pageSize: number, sort: string, filters: any[], isShowLoading: boolean = false, isScroll: boolean = false, filterBookingStatusCode: number = Constant.bookingStatusCode.Pending) => {
        if (isShowLoading)
            showLoading()
        BookingManagementService.getBookings(pageNumber, pageSize, sort, filters, filterBookingStatusCode)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    if (isScroll) {
                        let list = bookingData.map(x => Object.assign({}, x))
                        list = list.concat(result.data.ObjectList)
                        setBookingData(list)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: pageNumber,
                            pageSize: pageSize,
                            showLoading: false
                        })
                    } else {
                        setBookingData(result.data.ObjectList)
                    }
                    setTotalItems(result.data.TotalItems)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        let filterList = CommonService.getFilterList(filters)
        getBookings(pagination.current, pagination.pageSize, getSortString(sorter), filterList, false, false, selectedStatus)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSortString(sorter),
            filters: filterList
        })
        getServiceBookingSummary(filterList)
    }

    const getSortString = (sortObject?: any) => {
        if (!sortObject || !sortObject.column)
            return ''
        return `${sortObject.field}-${sortObject.order}`
    }

    const handleCancel = (objectData?: ServiceBooking) => {
        setVisibleCancelBookingModal(false)
        if (objectData) {
            // setTimeout(() => {
            //     getBookings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
            // }, 200)     
            getBookings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
            getServiceBookingSummary(gridConfigOptions.filters)
        }
    };

    const handleServiceCancel = (isCustomerUpdated: boolean) => {
        setVisibleServiceModal(false)

        if (isCustomerUpdated) {
            getBookings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
        }

    };

    const handleInfiniteOnLoad = (pageNumber: any) => {
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            showLoading: true
        })
        if (bookingData.length >= totalItems) {
            setGridConfigOptionsValue({
                ...gridConfigOptions,
                isLoadMore: false,
                showLoading: false
            });
            return;
        }
        getBookings(pageNumber, Constant.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, false, true)
    }

    const viewHistory = (data: any) => {
        setSelectedObject(data)
        setVisibleHistoryModal(true)
    }

    const handleHistoryCancel = () => {
        setVisibleHistoryModal(false)
    };

    const getTimeSlotName = (time?: Date) => {
        if (time) {
            let hour = time.getHours()
            let minute = time.getMinutes()
            let hourStr = hour > 9 ? hour.toString() : "0" + hour.toString()
            let minuteStr = minute > 9 ? minute.toString() : "0" + minute.toString()
            return `${hourStr}:${minuteStr}`
        }
        return ""
    }

    const editBookingData = (data: any) => {
        const cloneData = JSON.parse(JSON.stringify(data))
        if (cloneData.Appointment && cloneData.Appointment.DropOffTime) {
            let tempDate = cloneData.Appointment.DropOffTime
            cloneData.Appointment.DropOffTime = getTimeSlotName(new Date(tempDate))
            cloneData.Appointment.BookingDate = moment(tempDate)
            cloneData.Appointment.OriginalDropOffTime = new Date(tempDate)
        }
        setSelectedObject(cloneData)
        setVisibleEditBookingModal(true)
    }

    const handleEditBookingCancel = (objectData: any) => {
        setSelectedObject(null)
        setVisibleEditBookingModal(false)
        if (objectData) {
            getBookings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
            getServiceBookingSummary(gridConfigOptions.filters)
        }
    };

    const filterBookingsByStatusCode = (statusCode: number) => {

        getBookings(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, false, false, statusCode)
        setSelectedStatus(statusCode)
    }

    const isSlectedItem = (statusCode: number) => {
        return statusCode == selectedStatus
    }

    return (
        <div className="booking-management">
            <Row>
                <Col xs={{ span: 24 }} className="overview" xl={{ span: 0 }}>
                    {
                        isShowOrderSummaryLoading &&
                        <Spin indicator={<LoadingOutlined spin />}
                            className="loading-icon" />

                    }
                    <Row>
                        <Col xs={{ span: 24 }}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.All)}>
                            <h5 className="semi-bold-text">{t("booking_management.overview")}</h5>
                        </Col>
                        <Col xs={{ span: 6 }} className={`pending-status text-center ${isSlectedItem(Constant.bookingStatusCode.Pending) ? 'selected' : ''}`}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Pending)}>
                            <div>
                                <h4>{bookingSummaryData?.TotalPendingBookings || 0}</h4>
                                <h6>{t("booking_management.total_pending_bookings")}</h6>
                            </div>

                        </Col>
                        <Col xs={{ span: 6 }} className={`text-center ${isSlectedItem(Constant.bookingStatusCode.Active) ? 'selected' : ''}`}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Active)}>
                            <div>
                                <h4>{bookingSummaryData?.TotalActivedBookings || 0}</h4>
                                <h6>{t("booking_management.total_active_bookings")}</h6>
                            </div>

                        </Col>
                        <Col xs={{ span: 6 }} className={`completed-status text-center ${isSlectedItem(Constant.bookingStatusCode.Complete) ? 'selected' : ''}`}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Complete)}>
                            <div>
                                <h4>{bookingSummaryData?.TotalCompleteBookings || 0}</h4>
                                <h6>{t("booking_management.total_complete_bookings")}</h6>
                            </div>
                        </Col>
                        <Col xs={{ span: 6 }} className={`cancelled-status text-center ${isSlectedItem(Constant.bookingStatusCode.Cancelled) ? 'selected' : ''}`}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Cancelled)}>
                            <div>
                                <h4>{bookingSummaryData?.TotalCancelledBookings || 0}</h4>
                                <h6 className='cancelled-orders'>{t("booking_management.total_cancel_bookings")}</h6>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 0 }} className="overview" xl={{ span: 24 }}>
                    {
                        isShowOrderSummaryLoading &&
                        <Spin indicator={<LoadingOutlined spin />}
                            className="loading-icon" />

                    }
                    <Row>
                        <Col xs={{ span: 24 }} xxl={{ span: 3 }} xl={{ span: 4 }}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.All)}>
                            <h5 className="semi-bold-text">{t("booking_management.overview")}</h5>
                        </Col>
                        <Col xxl={{ span: 5 }} xl={{ span: 5 }} md={{ span: 6 }}
                            className={`flex-items align-space-between p-r-20 p-l-20 pending-status ${isSlectedItem(Constant.bookingStatusCode.Pending) ? 'selected' : ''}`}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Pending)}>
                            <div className="m-l-10">
                                <h4>{bookingSummaryData?.TotalPendingBookings || 0}</h4>
                                <h6>{t("booking_management.total_pending_bookings")}</h6>
                            </div>
                            <PendingBookingIcon />
                        </Col>
                        <Col xxl={{ span: 5 }} xl={{ span: 5 }} md={{ span: 6 }}
                            className={`flex-items align-space-between p-r-20 ${isSlectedItem(Constant.bookingStatusCode.Active) ? 'selected' : ''}`}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Active)}>
                            <div className="m-l-10">
                                <h4>{bookingSummaryData?.TotalActivedBookings || 0}</h4>
                                <h6>{t("booking_management.total_active_bookings")}</h6>
                            </div>

                            <ActiveBookingIcon />
                        </Col>

                        <Col xxl={{ span: 5 }} xl={{ span: 5 }} md={{ span: 6 }}
                            className={`flex-items align-space-between p-r-20 p-l-20 completed-status ${isSlectedItem(Constant.bookingStatusCode.Complete) ? 'selected' : ''}`}
                            onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Complete)}>
                            <div className="m-l-10">
                                <h4>{bookingSummaryData?.TotalCompleteBookings || 0}</h4>
                                <h6>{t("booking_management.total_complete_bookings")}</h6>
                            </div>
                            <CompleteBookingIcon />
                        </Col>
                        <Col xxl={{ span: 5 }} xl={{ span: 5 }} md={{ span: 6 }}
                            className={`flex-items align-space-between p-r-20 p-l-20 cancelled-status ${isSlectedItem(Constant.bookingStatusCode.Cancelled) ? 'selected' : ''}`}>
                            <div className="m-l-10"
                                onClick={() => filterBookingsByStatusCode(Constant.bookingStatusCode.Cancelled)}>
                                <h4>{bookingSummaryData?.TotalCancelledBookings || 0}</h4>
                                <h6 className='cancelled-orders'>{t("booking_management.total_cancel_bookings")}</h6>
                            </div>
                            <CancelBookingIcon />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(getColumns(), bookingData, totalItems, "Id", onTableChange)}
                    />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 0 }}>
                    <div className="infinite-scroll-container">
                        <InfiniteScroll
                            pageStart={1}
                            initialLoad={false}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!gridConfigOptions.showLoading && gridConfigOptions.isLoadMore}
                            useWindow={false}
                        >
                            <List
                                className="card-list"
                                dataSource={bookingData}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                                renderItem={(item: ServiceBooking, i: any) => (
                                    <List.Item>
                                        <Card className="card-selection card-details" key={i}>
                                            <Row className="flex-center">
                                                <Col xs={{ span: 22 }}>
                                                    <label className="title-field">{t("booking_management.header_booking_id") + ': ' + item.Id}</label>
                                                </Col>
                                                <Col xs={{ span: 2 }} className="actions text-right">
                                                    {(item.StatusCode == Constant.bookingStatusCode.Active || item.StatusCode == Constant.bookingStatusCode.Pending) && <DeleteOutlined className="action-icon delete-icon" onClick={() => cancelBooking(item)} />}
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("booking_management.header_rego")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.Vehicle?.RegoNo}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("booking_management.header_customer")}</label>
                                                    {item.Customer?.IsOrganisation && (
                                                        <label className="text-field truncate semi-bold-text">{item.Driver?.Name}</label>
                                                    )}
                                                    {!item.Customer?.IsOrganisation && (
                                                        <label className="text-field truncate semi-bold-text">{item.Customer?.Name}</label>
                                                    )}
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("booking_management.header_dms_booking_id")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.DmsBookingId}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("booking_management.header_creation_date")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.CreationTimestamp}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("booking_management.header_location")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.Appointment?.Location?.LocationCode}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <BookingStatusCell data={item.StatusCode}
                                                        editFunction={cancelBooking} />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                            {gridConfigOptions.showLoading && gridConfigOptions.isLoadMore &&
                                <div className="infinite-scroll-loading-container">
                                    <Spin />
                                </div>
                            }
                        </InfiniteScroll>
                    </div>
                </Col>
            </Row>
            {
                visibleCancelBookingModal && <CancelBooking cancellationCodes={cancellationCodes} isFromDMS={false} bookingData={selectedObject} handleCancel={handleCancel} />
            }
            {
                visibleServiceModal && <ServiceBookingDetail bookingData={selectedObject} handleCancel={handleServiceCancel} />
            }
            {
                visibleHistoryModal && <HistoryBookingDetail bookingData={selectedObject} handleCancel={handleHistoryCancel} />
            }
            {
                visibleEditBookingModal && <UpdateBooking bookingData={selectedObject} handleCancel={handleEditBookingCancel} />
            }

        </div>
    )
}

export default BookingManagement