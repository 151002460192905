import React from 'react';
import { Dealership, DealerStyle, Location, ServiceBooking, Vehicle } from '../models/appModels'
import { notification, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Constant as constant, IconType } from './constant'
import i18n from '../i18n';
import { StorageService, TranslationService } from './services';
export const CommonService = {
    setDealerStyle: function (dealerStyle: DealerStyle, isPreview: boolean = false) {
        if (dealerStyle) {
            let doc = document.querySelector(':root') as HTMLBodyElement
            if (isPreview) {
                doc = document.querySelector('.page-preview') as HTMLBodyElement
            }
            if (doc) {
                if (dealerStyle.PrimaryColor && dealerStyle.PrimaryColor.length > 0) {
                    doc.style.setProperty('--color-primary', dealerStyle.PrimaryColor);
                }

                if (dealerStyle.SecondaryColor && dealerStyle.SecondaryColor.length > 0) {
                    doc.style.setProperty('--color-secondary', dealerStyle.SecondaryColor);
                }

                if (dealerStyle.FocusColor && dealerStyle.FocusColor.length > 0) {
                    doc.style.setProperty('--color-focus', dealerStyle.FocusColor);
                }

                if (dealerStyle.ActiveColor && dealerStyle.ActiveColor.length > 0) {
                    doc.style.setProperty('--color-active', dealerStyle.ActiveColor);
                }

                if (dealerStyle.BackgroundColor && dealerStyle.BackgroundColor.length > 0) {
                    doc.style.setProperty('--color-background', dealerStyle.BackgroundColor);
                }

                if (dealerStyle.BackgroundColor2 && dealerStyle.BackgroundColor2.length > 0) {
                    doc.style.setProperty('--color-background2', dealerStyle.BackgroundColor2);
                }

                if (dealerStyle.BorderColor && dealerStyle.BorderColor.length > 0) {
                    doc.style.setProperty('--color-border', dealerStyle.BorderColor);
                }

                if (dealerStyle.BoxBackGroundColor && dealerStyle.BoxBackGroundColor.length > 0) {
                    doc.style.setProperty('--color-box-background', dealerStyle.BoxBackGroundColor);
                }

                if (dealerStyle.HightlightColor && dealerStyle.HightlightColor.length > 0) {
                    doc.style.setProperty('--color-highlight', dealerStyle.HightlightColor);
                }

                if (dealerStyle.LogoColor && dealerStyle.LogoColor.length > 0) {
                    doc.style.setProperty('--color-logo', dealerStyle.LogoColor);
                }

                if (dealerStyle.FontFamilyLight && dealerStyle.FontFamilyLight.length > 0) {
                    doc.style.setProperty('--font-family-light', dealerStyle.FontFamilyLight);
                }

                if (dealerStyle.FontFamilyRegular && dealerStyle.FontFamilyRegular.length > 0) {
                    doc.style.setProperty('--font-family-regular', dealerStyle.FontFamilyRegular);
                }

                if (dealerStyle.FontFamilySemiBold && dealerStyle.FontFamilySemiBold.length > 0) {
                    doc.style.setProperty('--font-family-semi-bold', dealerStyle.FontFamilySemiBold);
                }

                if (dealerStyle.FontFamilyBold && dealerStyle.FontFamilyBold.length > 0) {
                    doc.style.setProperty('--font-family-bold', dealerStyle.FontFamilyBold);
                }

                if (dealerStyle.FontHeading1Color && dealerStyle.FontHeading1Color.length > 0) {
                    doc.style.setProperty('--font-heading1-color', dealerStyle.FontHeading1Color);
                }

                if (dealerStyle.FontHeading2Color && dealerStyle.FontHeading2Color.length > 0) {
                    doc.style.setProperty('--font-heading2-color', dealerStyle.FontHeading2Color);
                }

                if (dealerStyle.FontHeading3Color && dealerStyle.FontHeading3Color.length > 0) {
                    doc.style.setProperty('--font-heading3-color', dealerStyle.FontHeading3Color);
                }

                if (dealerStyle.FontBodyColor && dealerStyle.FontBodyColor.length > 0) {
                    doc.style.setProperty('--font-body-color', dealerStyle.FontBodyColor);
                }

                if (dealerStyle.FontNavigationColor && dealerStyle.FontNavigationColor.length > 0) {
                    doc.style.setProperty('--font-navigation-color', dealerStyle.FontNavigationColor);
                }

                if (dealerStyle.LabelColor && dealerStyle.LabelColor.length > 0) {
                    doc.style.setProperty('--color-text-title', dealerStyle.LabelColor);
                }

                if (dealerStyle.InputBackgroundColor && dealerStyle.InputBackgroundColor.length > 0) {
                    doc.style.setProperty('--color-input-background', dealerStyle.InputBackgroundColor);
                }

                if (dealerStyle.TextColor && dealerStyle.TextColor.length > 0) {
                    doc.style.setProperty('--color-input-field', dealerStyle.TextColor);
                }
            }
        }
    },
    presentToast(type: IconType, message: any, messageHeader: any = null) {
        notification[type]({
            message: messageHeader ? messageHeader : 'Notification',
            description: message,

        });
    },
    handleErrorResponse: function (error: any) {
        if (error.message === 'Operation canceled.') {
            return
        }
        if (error.response) {
            if (error.response.status === 500) {
                this.presentToast('error', i18n.t('common.common_server_error'))
                return
            }
            else if (error.response.status === 400) {
                let msg = ""
                if (error.response.data.errors) {
                    for (var prop in error.response.data.errors) {
                        msg += error.response.data.errors[prop][0] + ";"
                    }
                } else if (error.response.data.error_description) {
                    msg = error.response.data.error_description
                } else if (error.response.data.Value) {
                    msg = error.response.data.Value
                }
                else {
                    msg = error.response.data
                }

                if (msg) {
                    let currentLanguage = StorageService.getLanguage()
                    if (currentLanguage && currentLanguage != 'en') {
                        TranslationService.detectLanguage(msg)
                            .then(result => {
                                if (currentLanguage && result.data && result.data.length > 0 && result.data[0].language && result.data[0].language != currentLanguage) {
                                    TranslationService.translateText(msg, "en", currentLanguage)
                                        .then(result => {
                                            if (result.data && result.data.translatedText) {
                                                this.presentToast('error', result.data.translatedText)
                                            } else {
                                                this.presentToast('error', msg)
                                            }
                                        })
                                } else {
                                    this.presentToast('error', msg)
                                }
                            })

                    } else {
                        this.presentToast('error', msg)
                    }
                }
            }
            else if (error.response.status === 401) {
                let message = ""
                if (error.response.data.Message && error.response.data.Message.Value)
                    message = error.response.data.Message.Value
                else
                    message = i18n.t('common.invalid_credentials')
                this.presentToast('error', message)
            }
        }

    },
    propToString: function <T>(): T {
        return new Proxy({}, {
            get({ }, prop) {
                return prop;
            }
        }) as T;
    },
    getDateString: function (date: any) {
        if (date) {
            date = new Date(date)
            let day = date.getDate()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            let month = date.getMonth() + 1
            if (month.toString().length == 1) {
                month = "0" + month
            }
            let value = day + '/' + month + '/' + date.getFullYear()
            return value
        }
        return ''
    },
    getTimeString: function (date: any) {
        if (date) {
            date = new Date(date)
            let hour = date.getHours()
            if (hour.toString().length == 1) {
                hour = "0" + hour
            }

            let minute = date.getMinutes()
            if (minute.toString().length == 1) {
                minute = "0" + minute
            }

            let value = hour + ':' + minute
            return value
        }
        return ""
    },
    getSortString: function (sortObject?: any) {
        if (!sortObject || !sortObject.column)
            return ''
        return `${sortObject.field}-${sortObject.order}`
    },
    dmsIntegrationOn: function () {
        let data = StorageService.getApplicationData()
        if (data)
            return data.AppSetting.DmsIntegration
        return false
    },
    getFirstCharUsername: function (username: string) {
        if (username)
            return username.substring(0, 1)
    },
    hasAccessPage: function (pageId: string) {
        let data = StorageService.getApplicationData()
        if (!data || data.AppPageRoles.length == 0)
            return false
        let selectedPages = data.AppPageRoles.filter((i: any) => i.PageId === pageId)
        if (selectedPages && selectedPages.length > 0)
            return true
        return false
    },
    hasAccessPageByPathName: function (pathName: string) {
        let pageId = ""
        if (pathName == constant.pages.UserManagement || pathName == constant.pages.RoleManagement)
            pageId = constant.appPageIds.UserManagement
        else if (pathName == constant.pages.GeneralSetup ||
            pathName == constant.pages.ThemeSetup ||
            pathName == constant.pages.EmailTemplateSetup ||
            pathName == constant.pages.SmsTemplateSetup)
            pageId = constant.appPageIds.GeneralSetup
        else if (pathName == constant.pages.LocationManagement)
            pageId = constant.appPageIds.LocationManagement
        else if (pathName == constant.pages.ServiceManagement)
            pageId = constant.appPageIds.ServiceManagement
        else if (pathName == constant.pages.BookingManagement)
            pageId = constant.appPageIds.BookingManagement
        if (!pageId)
            return false
        let data = StorageService.getApplicationData()
        if (!data || data.AppPageRoles.length == 0)
            return false
        let selectedPages = data.AppPageRoles.filter((i: any) => i.PageId === pageId)
        if (selectedPages && selectedPages.length > 0)
            return true
        return false
    },
    getFilterList: function (filters: any) {
        let result = []
        if (filters) {
            for (var item in filters) {
                if (filters[item] && filters[item])
                    result.push({
                        Field: item,
                        Values: filters[item]
                    })
            }
        }
        return result
    },
    calculateScrollMaxHeight: function (gridId: string, modalClass: string) {
        let modalElement = document.getElementsByClassName(modalClass);
        console.log(modalElement)
        let headerHeight = 0, footerHeight = 0, gridheight = 0
        if (modalElement && modalElement.length > 0) {
            let modal = modalElement[0].children
            for (let i = 0; i < modal.length; i++) {
                if (modal[i].className == "ant-modal-content") {
                    for (let j = 0; j < modal[i].children.length; j++) {
                        if (modal[i].children[j].className == "ant-modal-header") {
                            headerHeight = (modal[i].children[j] as HTMLElement).offsetHeight
                        }
                        else if (modal[i].children[j].className == "ant-modal-footer") {
                            footerHeight = (modal[i].children[j] as HTMLElement).offsetHeight
                        }
                    }
                }
            }
        }

        let gridElement = document.getElementById(gridId)
        if (gridElement) {
            gridheight = gridElement.offsetHeight
        }
        return headerHeight + footerHeight + gridheight + 50 + "px"
    },
    rad: function (x: number) {
        return x * Math.PI / 180;
    },
    getGeoDistance: function (lat1: number, long1: number, lat2: number, long2: number) {
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = this.rad(lat2 - lat1);
        var dLong = this.rad(long2 - long1);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.rad(lat1)) * Math.cos(this.rad(lat2)) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
    },
    getLocationAddress: function (location: Location | null) {
        let address = ""
        if (location) {
            if (location.StreetNumber) {
                address += location.StreetNumber
            }

            if (location.StreetLine1) {
                if (address.length > 0) {
                    address += ", " + location.StreetLine1
                } else {
                    address += location.StreetLine1
                }
            }

            if (location.StreetLine2) {
                if (address.length > 0) {
                    address += ", " + location.StreetLine2
                } else {
                    address += location.StreetLine2
                }
            }

            if (location.Suburb) {
                if (address.length > 0) {
                    address += ", " + location.Suburb
                } else {
                    address += location.Suburb
                }
            }

            if (location.State) {
                if (address.length > 0) {
                    address += ", " + location.State
                } else {
                    address += location.State
                }
            }
        }

        if (address.length == 0) {
            address = "_"
        }

        return address
    },
    getImgBase64: (img: any, callback: (value: any) => void) => {
        const isValidImgType = (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/gif');
        if (!isValidImgType) {
            CommonService.presentToast('warning', i18n.t("general_setup.upload_image_message"), i18n.t("common.notification_header"))
            return false;
        }
        const isLimit = img.size / 1024 / 1024 < 5;
        if (!isLimit) {
            CommonService.presentToast('warning', i18n.t("general_setup.limit_file_message"))
            return false;
        }
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    },
    validateVehicleMakeSearch: (booking: ServiceBooking, makeList: any[]) => {
        if (booking && booking.Vehicle && makeList && makeList.length > 0) {
            if ((booking.Vehicle.MakeId && !makeList.some(s => s.Id == booking.Vehicle.MakeId)) ||
                (booking.Vehicle.Make && booking.Vehicle.Make.MakeId && !makeList.some(s => s.MakeId == booking.Vehicle.Make.MakeId))) {
                CommonService.presentToast('error', i18n.t("vehicle_detail.booking_search_message", { makeId: booking.Vehicle.Make.MakeId }), i18n.t("common.notification_header"));
                return false;
            }
        }
        return true;
    },
    validateVehicleMake: (vehicle: Vehicle, makeList: any[]) => {
        if (vehicle && makeList && makeList.length > 0) {
            if ((vehicle.MakeId && !makeList.some(s => s.Id == vehicle.MakeId)) ||
                (vehicle.Make && vehicle.Make.MakeId && !makeList.some(s => s.MakeId == vehicle.Make.MakeId))) {
                return false;
            }
        }
        return true;
    },
    excludeSpecialCharactersInput(value: any) {
        if (value) {
            const filteredValue = value.toString().replace(/[{}`~!#$%^&*<>/\\|\[\]"']/g, '');
            return filteredValue;
        }
        return ''
    },
    allowModyfyAndAccessPage: function (pageId: string) {
        let data = StorageService.getApplicationData()
        if (!data || data.AppPageRoles.length == 0)
            return false
        let selectedPages = data.AppPageRoles.filter((i: any) => i.PageId === pageId && i.AllowModify)
        if (selectedPages && selectedPages.length > 0)
            return true
        return false
    },
    updateLocationDealershipGlobalName(locations: Location[], languageCode: string | null = null) {
        if (!languageCode) {
            let languageStr = process.env.REACT_APP_LANGUAGES
            if (languageStr) {
                let availableLanguages = languageStr.split(",")
                if (availableLanguages && availableLanguages.length > 0) {
                    languageCode = availableLanguages[0]
                }
            }
        }

        if (locations && locations.length > 0 && languageCode) {
            locations.forEach(location => {
                if (location.DealershipGlobalNames && location.DealershipGlobalNames.length > 0) {
                    let currentName = location.DealershipGlobalNames.find(n => n.LanguageCode == languageCode)
                    if (currentName) {
                        location.DealershipName = currentName.Name
                    }
                }
            })
        }

        return locations
    },
    updateDealershipGlobalName(dealerships: Dealership[], languageCode: string | null = null) {
        if (!languageCode) {
            let languageStr = process.env.REACT_APP_LANGUAGES
            if (languageStr) {
                let availableLanguages = languageStr.split(",")
                if (availableLanguages && availableLanguages.length > 0) {
                    languageCode = availableLanguages[0]
                }
            }
        }

        if (dealerships && dealerships.length > 0 && languageCode) {
            dealerships.forEach(dealership => {
                if (dealership.DealershipGlobalNames && dealership.DealershipGlobalNames.length > 0) {
                    let currentName = dealership.DealershipGlobalNames.find(n => n.LanguageCode == languageCode)
                    if (currentName) {
                        dealership.DealerName = currentName.Name
                    }
                }
            })
        }

        return dealerships
    },
    getTimeBySeconds: function (seconds: number) {
        return {
            minutes: Math.floor(seconds / 60),
            seconds: seconds % 60,
        };
    }
}
