import { Button, Card, Col, Empty, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalOptions, Customer } from '../../../models/appModels';
import { CommonService, Constant } from '../../../services/services';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";
export interface CustomerModalOptions extends ModalOptions<Customer> {
}

const CustomerSelection = (props: CustomerModalOptions) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [displayData, setDisplayData] = useState<Customer[]>([])

    const [cache, setCache] = useState<CellMeasurerCache>(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100
    }));

    const modelSearchFrom = "modelSearchForm"
    const modalClass = "vertical-modal"

    const closeForm = () => {
        props.handleCancel()
    }

    const calculateScrollMaxHeight = (gridId: string, modalClass: string) => {
        return CommonService.calculateScrollMaxHeight(gridId, modalClass)
    }

    const calculateScrollHeight = () => {
        document.documentElement.style.setProperty(Constant.cssVariables.ModalHeightScrollList, calculateScrollMaxHeight(modelSearchFrom, modalClass));
    }

    useEffect(() => {
        calculateScrollHeight()

        let displayData = props.objectData.map((d: Customer) => {
            if(d.Mobile){
                d.DisplayMobile = d.Mobile.slice(0, 2) + d.Mobile.slice(2).replace(/.(?=...)/g, '*')
            }
            
            if(d.Email){
                d.DisplayEmail = d.Email.slice(0, 3) + d.Email.slice(3).replace(/.(?=...)/g, '*')
            }
            
            return d
        })
        setDisplayData(displayData)
    }, [])

    const customerClick = (customer: Customer) => {
        props.handleCancel(customer)
    };

    const renderItem = ({ index, key, style, parent }: any) => {
        const item = displayData[index];
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}>
                <div key={key} style={style} onClick={() => customerClick(item) }>
                    <Card className="card-selection">
                        <Row>
                            <Col xs={{ span: 12 }}>
                                <label className="title-field">{t("customer_detail.first_name")}</label>
                                <label className="text-field semi-bold-text">{item.FirstName}</label>
                            </Col>                          
                            <Col xs={{ span: 12 }}>
                                <label className="title-field">{t("customer_detail.last_name")}</label>
                                <label className="text-field truncate semi-bold-text">{item.LastName}</label>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <label className="title-field">{t("customer_detail.mobile_phone")}</label>
                                <label className="text-field semi-bold-text">{item.DisplayMobile}</label>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <label className="title-field">{t("customer_detail.email")}</label>
                                <label className="text-field semi-bold-text">{item.DisplayEmail}</label>
                            </Col>
                        </Row>
                    </Card>
                </div>

            </CellMeasurer>
        );
    };

    const rowHeightCalc = (idx: any) => {
        return cache.rowHeight(idx) + 10
    }
    return (
        <Modal
            title={t("customer_detail.customer_selection_title")}
            visible={true}
            onOk={form.submit}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            className={`portal-modal modal-open ${modalClass}`}
            maskClosable={false}
            footer={null}
            transitionName=""
            maskTransitionName=""
        >
            {props.objectData && props.objectData.length > 0 &&
                <div className="list-container">
                    <AutoSizer>
                        {({ width, height }) => {
                            return <List
                                width={width}
                                height={height}
                                rowHeight={rowHeightCalc}
                                deferredMeasurementCache={cache}
                                rowRenderer={renderItem}
                                rowCount={props.objectData.length}
                                overscanRowCount={3}
                            />
                        }
                        }
                    </AutoSizer>
                </div>
            }
        </Modal>

    )
}

export default CustomerSelection