import React, { useEffect } from 'react';
import './login.scss';
import './../../layouts/loginLayout/loginLayout.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row } from 'antd';
import { Constant, StorageService, AuthService, CommonService, AppSettingService, LocationManagementService } from './../../services/services'
import { useHistory } from 'react-router-dom';
import { SettingFilled } from '@ant-design/icons'
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { useGlobalState } from '../../services/state';
import { AppPageRole } from '../../models/appModels';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const Login = (props: any) => {
    const { t } = useTranslation();
    let history = useHistory();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [, setDealerships] = useGlobalState('dealerships');

    useEffect(() => {
        let dealerId = StorageService.getDealerIdData()
        if (!dealerId) {
            goToPage(Constant.pages.Config)
        }
        return () => {
            AuthService.cancelRequest()
            AppSettingService.cancelRequest()
        }
    }, [])

    const onSubmit = (data: any) => {
        if (data) {
            let dealerId = StorageService.getDealerIdData()
            showLoading()
            AuthService.login(data.UserName, data.Password, dealerId!).finally(() => dismissLoading())
                .then(() => {
                    getApplicationData(data.UserName)                    

                    LocationManagementService.getDealerships().then(result => {
                        if(result && result.data){
                            let dealership = CommonService.updateDealershipGlobalName(result.data)
                            setDealerships(dealership)
                        }
                    }).catch(error => CommonService.handleErrorResponse(error))
                    
                }, error => {
                    CommonService.handleErrorResponse(error)
                })
        }
    };

    const getDmsIntegrationValue = () => {
        AppSettingService.getAppSetting()
            .then(res => {
                StorageService.setDmsIntegrationData(res.data ? res.data.DmsIntegration : false)
            }, error => {
                CommonService.handleErrorResponse(error)
            })
    }

    const getApplicationData = (username: string) => {
        showLoading()
        AuthService.getApplicationData(username).finally(() => dismissLoading())
            .then(res => {
                if (res.data) {
                    StorageService.setApplicationData(res.data)

                    goToPageByUser(res.data.AppPageRoles);
                }
            }, error => {
                CommonService.handleErrorResponse(error)
            })
    }

    const goToPageByUser = (pages: AppPageRole[]) => {
        if (pages.length == 0) {
            goToPage(Constant.pages.NoPermission)
            return
        }

        let firstPageId = pages[0].PageId

        let userPages = pages.filter(x => x.PageId == Constant.appPageIds.UserManagement)
        if (userPages.length > 0)
            firstPageId = userPages[0].PageId

        let page = ""
        if (firstPageId == Constant.appPageIds.UserManagement)
            page = Constant.pages.UserManagement
        else if (firstPageId == Constant.appPageIds.GeneralSetup)
            page = Constant.pages.GeneralSetup
        else if (firstPageId == Constant.appPageIds.LocationManagement)
            page = Constant.pages.LocationManagement
        else if (firstPageId == Constant.appPageIds.ServiceManagement)
            page = Constant.pages.ServiceManagement
        else if (firstPageId == Constant.appPageIds.BookingManagement)
            page = Constant.pages.BookingManagement

        if (page)
            goToPage(page)
        else
            goToPage(Constant.pages.NoPermission)
    }

    const goToPage = (page: string) => {
        history.push(page)
    }

    return (
        <div className="login-page">
            <div className="config-icon">
                <SettingFilled onClick={() => goToPage(Constant.pages.Config)} />
            </div>
            <p className="page-title">{t('login_page.service_booking_management')}</p>
            <Form
                {...layout}
                form={form}
                name="loginForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={t("login_page.username")}
                            name="UserName" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("login_page.username_required") }]}
                            normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("login_page.password")}
                            name="Password" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("login_page.password_required") }]}
                        >
                            <Input.Password className="no-border" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="page-footer">
                        <span className="forgot-pw" onClick={() => goToPage(Constant.pages.ForgotPassword)}>{t('login_page.forgot_your_password')}</span>
                        <Button className="login-btn primary-btn" htmlType="submit">
                            {t("login_page.login")}
                        </Button>
                    </Col>

                </Row>
            </Form>
        </div>
    )
}

export default Login