import React, { useState, useEffect } from 'react';
import './addUpdateLocation.scss'
import { Form, Input, Modal, Select, Row, Col, Table, Tabs, TimePicker, AutoComplete, Button, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { ModalOptions, Location, LocationTimeslot, Country, ServiceAdvisor, LocationWorkingDay } from '../../../models/appModels';
import {
    Constant,
    LocationManagementService,
    CommonService,
    AlignType
} from './../../../services/services'
import LoadingContext from './../../utils/loadingCompt/loadingContext'
import moment from 'moment'
import { useGlobalState } from '../../../services/state';
import { EditableCell, EditCommandCell } from '../../utils/gridColumn/gridColumn'
import AddUpdateTeam from '../addUpdateTeam/addUpdateTeam';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const { Option } = Select;
const { TabPane } = Tabs;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

const locationModel = CommonService.propToString<Location>()

export interface LocationModalOptions extends ModalOptions<Location> {
    statusList: any[],
    countryList: Country[]
}

const AddUpdateLocation = (props: LocationModalOptions) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)

    const [form] = Form.useForm();
    const [teamData, setTeamData] = useState<LocationTimeslot[]>([])
    const [countryStates] = useGlobalState('countryStates');
    const [stateOptions, setStateOptions] = useState<{ value: string }[]>([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState<LocationTimeslot | null>();
    const [dealerships] = useGlobalState('dealerships');
    const [advisors, setAdvisors] = useState<ServiceAdvisor[]>([]);
    const [currentAdvisor, setCurrentAdvisor] = useState<ServiceAdvisor | null>(null);
    const [workingDayData, setWorkingDayData] = useState<LocationWorkingDay[]>([])

    let previousFormData: any

    const onSubmit = (location: Location) => {
        location.LocationTimeslot = teamData
        location.LocationWorkingDay = workingDayData
        if (!location.BookingInterval) {
            location.BookingInterval = 5
        }

        if (props.objectData) {
            Object.assign(props.objectData, location)
            addUpdateLocation(props.objectData)
        } else {
            addUpdateLocation(location)
        }
    };

    const addUpdateLocation = (location: Location) => {
        showLoading()

        location.BookingStartTime = new Date(location.BookingStartTimeMap)
        location.BookingEndTime = new Date(location.BookingEndTimeMap)

        if(currentAdvisor){
            location.DefaultServiceAdvisor = currentAdvisor
        } else {
            location.DefaultServiceAdvisor = null
            location.DefaultServiceAdvisorId = undefined
        }
        

        LocationManagementService.addUpdateLocations(location)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data > 0) {
                    CommonService.presentToast('success', t('location_management.update_successful'), t("common.notification_header"))
                    props.handleCancel(location)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const closeForm = () => {
        props.handleCancel()
    }

    const stateSearch = (searchText: string) => {
        if (searchText) {
            let options = countryStates.filter(s => s.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            setStateOptions(options)
        } else {
            setStateOptions(countryStates)
        }
    };

    const handleSave = (row: LocationTimeslot) => {
        const newData = [...teamData];
        const index = newData.findIndex(item => row.Id && row.Id === item.Id);

        if (index >= 0) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
        } else {
            newData.push(row)
        }

        setTeamData(newData);
    };

    useEffect(() => {
        setStateOptions(countryStates)
        if (!props.objectData) {
            //var dealerId = dealerships && dealerships.length == 1 ? dealerships[0] : null
            form.setFieldsValue({
                [locationModel.StatusCode]: Constant.statusCode.Active,
                [locationModel.DealershipId]: dealerships && dealerships.length == 1 ? dealerships[0].DealerId : null
            });

        }
        else {
            setTeamData(props.objectData.LocationTimeslot)
            props.objectData.BookingStartTimeMap = moment(props.objectData.BookingStartTime)
            props.objectData.BookingEndTimeMap = moment(props.objectData.BookingEndTime)
            form.setFieldsValue(props.objectData)

            if(props.objectData.DefaultServiceAdvisor){
                setCurrentAdvisor(props.objectData.DefaultServiceAdvisor)
            }

            if(props.objectData.LocationCode && props.objectData.DealershipId){
                LocationManagementService.getServiceAdvisors(props.objectData.LocationCode, props.objectData.DealershipId)
                                    .then(advisorResult => {
                                        if(advisorResult.data){
                                            setAdvisors(advisorResult.data)
                                        }
                                    })
                                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }

        const data: LocationWorkingDay[] = [];
        for (let i = 0; i < 7; ++i) {
            data.push({
                Id: 0,
                LocationId: 0,
                DayOfTheWeek: i,
                DayOfTheWeekDescription: t(Constant.daysOfWeek[i]),
                IsWorkingDay: i == 0 ? false : true
            });
        }
        
        if (props.objectData) {
            if (props.objectData.LocationWorkingDay && props.objectData.LocationWorkingDay.length > 0) {
                props.objectData.LocationWorkingDay.forEach((day: LocationWorkingDay) => {
                    let item = data.filter(d => d.DayOfTheWeek == day.DayOfTheWeek);
                    if (item && item.length > 0) {
                        item[0].Id = day.Id
                        item[0].LocationId = props.objectData.Id
                        item[0].IsWorkingDay = day.IsWorkingDay
                    }
                })
            }
        } 

        setWorkingDayData(data)


        return () => {
            LocationManagementService.cancelRequest()
        }
    }, [])

    const columns = [
        {
            title: t('location_management.header_name'),
            dataIndex: 'Name',
            key: 'Name'
        },
        {
            title: t('location_management.working_time_range'),
            render: (value: any, record: any) => (
                <label>{record ? CommonService.getTimeString(record.BookingStartTime) + ' - ' + CommonService.getTimeString(record.BookingEndTime) : ""}</label>
            )
        },
        {
            title: t('location_management.non_working_time_range'),
            render: (value: any, record: any) => (
                <label>{record && record.ExclusionStartTime ? CommonService.getTimeString(record.ExclusionStartTime) + ' - ' + CommonService.getTimeString(record.ExclusionEndTime) : ""}</label>
            )
        },
        {
            title: t('common.action'),
            key: 'Id',
            render: (value: any, record: any) => (
                <EditCommandCell data={record} editFunction={editData} />
            ),
            align: 'center' as AlignType
        }
    ]

    const editData = (data: any) => {
        setSelectedObject(data)
        setVisibleModal(true)
    }

    const handleCancel = (objectData?: LocationTimeslot) => {
        setVisibleModal(false)
        if (objectData)
            handleSave(objectData)
    };

    const onAddNew = () => {
        setVisibleModal(true)
        setSelectedObject(null)
    }

    const setpreviousFormData = () => {
        previousFormData = form.getFieldsValue()
    }

    const checkFieldChange = (fieldName: string, newValue: any) => {
        if (previousFormData) {
            if (previousFormData[fieldName] != newValue) {
                return true
            }
        } else if (newValue) {
            return true
        }

        return false;
    }

    const checkAndGetDmsLocation = (fieldData: string) => {
        let value = form.getFieldValue(fieldData)
        if (checkFieldChange(fieldData, value)) {
            doGetDmsLocation()
        }
    }

    const doGetDmsLocation = () => {
        let dealershipId = form.getFieldValue(locationModel.DealershipId)
        let locationCode = form.getFieldValue(locationModel.LocationCode)

        if (dealershipId && locationCode) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("location_management.get_dms_location_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    showLoading()
                    LocationManagementService.getDmsLocation(dealershipId, locationCode)
                        .finally(() => dismissLoading())
                        .then(result => {
                            if (result.data) {
                                result.data.BookingStartTimeMap = moment(result.data.BookingStartTime)
                                result.data.BookingEndTimeMap = moment(result.data.BookingEndTime)
                                form.setFieldsValue(result.data)

                                LocationManagementService.getServiceAdvisors(locationCode, dealershipId)
                                    .then(advisorResult => {
                                        if(advisorResult.data){
                                            setAdvisors(advisorResult.data)
                                        }
                                    })
                                    .catch(error => CommonService.handleErrorResponse(error))
                            } else {
                                let dealer = dealerships.find(d => d.DealerId == dealershipId)
                                CommonService.presentToast('info', t("location_management.no_dms_location", { locationCode: locationCode, dealer: dealer?.DealerName }), t("common.notification_header"))
                            }
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                },
                onCancel() {
                }
            });
        }
    }

    const onAdvisorChange = (value: string) => {
        if (value) {
            let advisor = advisors.filter(a => a.DmsUserId == value)
            if (advisor && advisor.length > 0) {
                setCurrentAdvisor(advisor[0]);
            }
        } else {
            setCurrentAdvisor(null);
        }
    }

    const components = {
        body: {
            cell: EditableCell
        },
    };

    const workingDayColumns = [
        {
            title: '',
            dataIndex: 'DayOfTheWeekDescription',
            key: 'DayOfTheWeekDescription',
            width: 150
        },
        {
            title: t('location_management.working_time_range'),
            dataIndex: 'IsWorkingDay',
            dataIndexex: 'IsWorkingDay',
            editable: true,
            cellType: Constant.cellType.Checkbox,
            width: 150
        }
    ];

    const columnsRender = workingDayColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: LocationWorkingDay) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                dataIndexex: col.dataIndexex,
                title: col.title,
                cellType: col.cellType,
                width: col.width,
                handleSave: handleSaveWorkingDay
            }),
        };
    });

    const handleSaveWorkingDay = (row: LocationWorkingDay) => {
        const newData = [...workingDayData];
        const index = newData.findIndex(item => row.DayOfTheWeek === item.DayOfTheWeek);
        if (index >= 0) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setWorkingDayData(newData);
        }
    };

    return (
        <Modal
            title={!props.objectData ? t("location_management.add_location") : t("location_management.update_location")}
            visible={true}
            onOk={form.submit}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            className="location-modal"
            maskClosable={false}
        >
            <Form
                {...layout}
                form={form}
                name="roleForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t("location_management.header_location")} key="1" className="m-l-5 m-r-5">
                        <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_dealership")}
                                    name={locationModel.DealershipId} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("role_management.dealership_required") }]}
                                >
                                    <Select onChange={doGetDmsLocation}>
                                        {
                                            dealerships.map((n, index) => <Option key={index} value={n.DealerId}>{n.DealerName}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_code")}
                                    name={locationModel.LocationCode} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("location_management.location_code_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={40} onFocus={setpreviousFormData} onBlur={() => checkAndGetDmsLocation(locationModel.LocationCode)} />
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_status")}
                                    name={locationModel.StatusCode} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("role_management.status_required") }]}
                                >
                                    <Select>
                                        {
                                            props.statusList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_name")}
                                    name={locationModel.LocationName} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("location_management.location_name_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                       
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_booking_start")}
                                    name={locationModel.BookingStartTimeMap} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("location_management.start_time_required") }]}
                                >
                                    <TimePicker format="HH:mm" />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_booking_end")}
                                    name={locationModel.BookingEndTimeMap} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("location_management.end_time_required") },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value) {
                                                let start = getFieldValue('BookingStartTimeMap')
                                                if (!start || start >= value) {
                                                    return Promise.reject(t("location_management.start_end_error"));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    })]}
                                >
                                    <TimePicker format="HH:mm" />
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_interval")}
                                    name={locationModel.BookingInterval} colon={false} labelAlign="left">
                                    <InputNumber min={5} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_stop_booking")}
                                    name={locationModel.StopBookingAtUsageRate} colon={false} labelAlign="left">
                                    <InputNumber min={0} />
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_unavailable_days")}
                                    name={locationModel.LeadTimeInDays} colon={false} labelAlign="left" >
                                    <InputNumber min={0} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("service_detail.service_advisor")}
                                    colon={false} labelAlign="left"
                                >
                                    <Select showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={currentAdvisor ? currentAdvisor.DmsUserId : undefined}
                                        onChange={onAdvisorChange}
                                    >
                                        <Option value="">{t("common.none")}</Option>
                                        {
                                            advisors.map((n, index) => <Option key={index} value={n.DmsUserId}>{n.Name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={t("location_management.header_address")} key="2" className="m-l-5 m-r-5">
                        <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_email")}
                                    name={locationModel.Email} colon={false} labelAlign="left"
                                    rules={[{ type: "email", message: t("customer_detail.email_invalid") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_phone")}
                                    name={locationModel.PhoneNumber} colon={false} labelAlign="left"
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={30} />
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_street_no")}
                                    name={locationModel.StreetNumber} colon={false} labelAlign="left"
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={128} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_line_1")}
                                    name={locationModel.StreetLine1} colon={false} labelAlign="left"
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={128} />
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_line_2")}
                                    name={locationModel.StreetLine2} colon={false} labelAlign="left"
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={128} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_Suburb")}
                                    name={locationModel.Suburb} colon={false} labelAlign="left"
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_state")}
                                    name={locationModel.State} colon={false} labelAlign="left"
                                >
                                    <AutoComplete options={stateOptions} onSearch={stateSearch} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_country")}
                                    name={locationModel.CountryCode} colon={false} labelAlign="left"
                                >
                                    <Select>
                                        {
                                            props.countryList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_postcode")}
                                    name={locationModel.PostCode} colon={false} labelAlign="left"
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={15} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={t("location_management.teams")} key="3" className="m-l-5 m-r-5">
                        <div className="action-btn-group">
                            <Button className="primary-btn" icon={<PlusOutlined />} onClick={onAddNew}>
                                {t("location_management.add_team")}
                            </Button>
                        </div>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    //label={t("location_management.teams")}
                                    colon={false} labelAlign="left"
                                >
                                    <Table size="small"
                                        columns={columns}
                                        rowKey="Id"
                                        dataSource={teamData}
                                        pagination={false}
                                        scroll={{ y: 240 }}
                                    />
                                </Form.Item>

                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={t("location_management.working_day")} key="4" className="m-l-5 m-r-5">
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    colon={false} labelAlign="left"
                                >
                                    <Table className="custom-table" size="small"
                                        components={components}
                                        columns={columnsRender}
                                        rowKey="DayOfTheWeek"
                                        dataSource={workingDayData}
                                        pagination={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Form>
            {
                visibleModal &&
                <AddUpdateTeam handleCancel={handleCancel} objectData={selectedObject} statusList={props.statusList} ></AddUpdateTeam>
            }
        </Modal>
    )
}

export default AddUpdateLocation
