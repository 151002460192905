import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, Row, Col, Table, Checkbox, InputNumber, Upload, Button, Tag, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppPageRole, Make, ModalOptions, Model, ModelType, RepairOrderJob, Role, RoleCategory, ServiceOperationCode } from '../../../models/appModels';
import {
    CommonService as commonService,
    Constant,
    ClientService,
    UserManagementService,
    CommonService,
    ServiceOperationCodeService
} from './../../../services/services'
import LoadingContext from './../../utils/loadingCompt/loadingContext'
import { useGlobalState } from '../../../services/state';
import { CloseOutlined, DownOutlined, SyncOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import ServiceSelection from '../serviceSelection/serviceSelection';
import AddImage from './../../../assets/icons/svg/add-image.svg'
import { ServiceOperationCodeExtra } from '../../../models/serviceOperationCodeExtra';
import './addUpdateService.scss';

let previousFormData: any

const { confirm } = Modal;
const { Option } = Select;
const { TextArea, Search } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

const serviceModel = commonService.propToString<ServiceOperationCode>()

export interface ServiceModalOptions extends ModalOptions<ServiceOperationCode> {
    statusList: any[],
    makeList: Make[],
    dmsIntegrationOn: boolean,
    modelTypeList: ModelType[]

}

const AddUpdateService = (props: ServiceModalOptions) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = useContext(LoadingContext)
    const [selectedModel, setSelectedModel] = useState<Model | null>()
    const [visibleSearchModal, setVisibleSearchModal] = useState(false);
    const [serviceOperationCodeSearchList, setServiceOperationCodeSearchList] = useState<ServiceOperationCode[]>([]);
    const [dealerships] = useGlobalState('dealerships');
    const [imageBase64, setImageBase64] = useState<string | null | undefined>();
    const [imageUrl, setImageUrl] = useState<string | null | undefined>()
    const [serviceCodeExtraData, setServiceCodeExtraData] = useState<ServiceOperationCodeExtra[]>([]);
    const [fromServiceCodeExtra, setFromServiceCodeExtra] = useState(false);

    const [form] = Form.useForm();
    const onSubmit = (service: ServiceOperationCode) => {
        // service.ModelId = selectedModel ? selectedModel.Id : undefined
        // service.MakeId = selectedModel ? selectedModel.ModelType.MakeId : undefined
        // service.Model = undefined
        if (service.MakeId && service.MakeId < 0) {
            service.MakeId = undefined
        }
        if (props.objectData) {
            updateService(service, props.objectData.Id)
        }
        else {
            addService(service)
        }
    };

    const addService = (service: ServiceOperationCode) => {
        service.ImageBase64 = imageBase64 ? imageBase64 : ''
        service.ImageUrl = imageUrl ? imageUrl : ''
        service.ServiceOperationCodeExtra = serviceCodeExtraData
        showLoading()
        ServiceOperationCodeService.addServiceOperationCode(service)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    commonService.presentToast('success', t('service_management.create_successful'), t("common.notification_header"))
                    props.handleCancel(service)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const updateService = (service: ServiceOperationCode, id: number) => {
        service.Id = id
        service.ImageBase64 = imageBase64 ? imageBase64 : ''
        service.ImageUrl = imageUrl ? imageUrl : ''
        service.ServiceOperationCodeExtra = serviceCodeExtraData
        showLoading()
        ServiceOperationCodeService.updateServiceOperationCode(service)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    commonService.presentToast('success', t('service_management.update_successful'), t("common.notification_header"))
                    props.handleCancel(service)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const closeForm = () => {
        props.handleCancel()
    }

    const isLinkedToDmsRecord = () => {
        if (props.objectData && props.objectData.DmsOperationCode)
            return true
        return false
    }

    const isProcessDmsRecord = () => {
        return (props.dmsIntegrationOn && !props.objectData) || isLinkedToDmsRecord();
    }

    const setpreviousFormData = () => {
        previousFormData = form.getFieldsValue()
    }

    const getServiceOperationCodeByOpCodeAndModelId = (opCpde: string, modelId?: number, excludeServiceCode: boolean = false) => {
        showLoading()
        ServiceOperationCodeService.getServiceOperationCodeByOpCodeAndModelId(opCpde, modelId)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data || !result.data.DmsOperationCode) {
                    CommonService.presentToast('warning', t("service_management.dms_operation_code_not_found"), t("common.notification_header"))
                }
                populateFromData(result.data, excludeServiceCode)

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const getServiceOperationCodesByOpCode = (opCpde: string, dealershipId?: number) => {
        showLoading()
        ServiceOperationCodeService.getServiceOperationCodesByOpCode(opCpde, dealershipId)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data || result.data.length == 0) {
                    CommonService.presentToast('warning', t("service_management.dms_operation_code_not_found"), t("common.notification_header"))
                } else if (result.data.length == 1) {
                    populateFromData(result.data[0])
                } else {
                    setServiceOperationCodeSearchList(result.data)
                    setVisibleSearchModal(true)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const populateFromData = (data?: ServiceOperationCode, excludeServiceCode: boolean = false) => {
        form.setFieldsValue({
            [serviceModel.Name]: !data ? "" : data.Name,
            [serviceModel.Description]: !data ? "" : data.Description,
            ["StandardPrice"]: !data ? "" : data.StandardPrice,
            ["StandardHour"]: !data ? "" : data.StandardHour,
            ["DmsCodebookSourceCode"]: !data ? null : data.DmsCodebookSourceCode,
            [serviceModel.DmsOperationCode]: data?.DmsOperationCode
        });
        if (!excludeServiceCode) {
            form.setFieldsValue({
                [serviceModel.ServiceCode]: !data ? "" : data.ServiceCode
            });
        }
    }

    const onSearchDmsOperationCode = (value: any) => {
        if (value) {
            let modelId = form.getFieldValue(["Model", "Id"])
            getServiceOperationCodeByOpCodeAndModelId(value, modelId)
        }

    }

    const setModelInfo = (model: Model) => {
        form.setFieldsValue({
            Model: model
        })
        setSelectedModel(model)
    }

    const clearModel = () => {
        form.setFieldsValue({
            Model: null
        })
        setSelectedModel(null)
    }

    const closeSearchServiceCode = (obj?: ServiceOperationCode) => {
        setVisibleSearchModal(false)
        setServiceOperationCodeSearchList([])
        if (obj) {
            if (!fromServiceCodeExtra)
                populateFromData(obj)
            else
            {
                updateServiceCodeExtraData(obj)
            }
        }
        setFromServiceCodeExtra(false)
    }

    const searchModel = () => {
        setVisibleSearchModal(true)
    }

    const canClearModel = () => {
        let model = form.getFieldValue('Model')
        return model ? true : false
    }

    const checkAndGetDmsService = (fieldData: string) => {
        let value = form.getFieldValue(fieldData)
        if(value){
            form.setFieldsValue({
                [fieldData]: value.toString().toUpperCase()
            })
        }
        if (checkFieldChange(fieldData, value)) {
            doGetDmsService()
        }
    }

    const checkFieldChange = (fieldName: string, newValue: any) => {
        if (previousFormData) {
            if (previousFormData[fieldName] != newValue) {
                return true
            }
        } else if (newValue) {
            return true
        }

        return false;
    }

    const doGetDmsService = () => {
        let opCode = form.getFieldValue(serviceModel.DmsOperationCode)
        let dealershipId = form.getFieldValue("DealershipId")

        if (opCode) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("service_management.get_dms_service_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    getServiceOperationCodesByOpCode(opCode, dealershipId)
                },
                onCancel() {
                }
            });
        }
    }

    const refreshData = () => {
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("common.confirm_reload_data"),
            okText: t("common.ok"),
            cancelText: t("common.cancel"),
            className: "portal-modal",
            onOk() {
                let modelId = form.getFieldValue(["Model", "Id"])
                let dmsOpCode = form.getFieldValue(serviceModel.DmsOperationCode)
                getServiceOperationCodeByOpCodeAndModelId(dmsOpCode, modelId, true)
            },
            onCancel() {
            }

        });
    }

    const beforeUploadPhoto = (img: any) => {
        CommonService.getImgBase64(img, imageUrl => {
            setImageBase64(imageUrl);
        });
        return true
    }

    const removeLogo = () => {
        setImageBase64("");
        setImageUrl("");
    }

    const deleteOperationCode = (item: ServiceOperationCodeExtra) => {
        let tempData = serviceCodeExtraData.map(x => Object.assign({}, x))
        tempData = tempData.filter(x => x.DmsOperationCode != item.DmsOperationCode)
        setServiceCodeExtraData(tempData)
    }

    const searchDmsOperationCode = (e: any) => {
        let value = e
        if (value) {
            if (checkFieldChange("dmsOperationCodeSearch", value)) {
                let dmsOperationCode = form.getFieldValue(serviceModel.DmsOperationCode)
                let existedItems = serviceCodeExtraData.filter(x => x.DmsOperationCode == value.trim())
                if (dmsOperationCode == value || existedItems.length > 0) {
                    
                    form.setFieldsValue({
                        dmsOperationCodeSearch: ""
                    })
                    CommonService.presentToast('warning', t("service_management.extra_operation_code_duplicate_message"), t("common.notification_header"))
                    return
                }
                let dealershipId = form.getFieldValue("DealershipId")
                showLoading()
                ServiceOperationCodeService.getServiceOperationCodesByOpCode(value, dealershipId)
                    .finally(() => dismissLoading())
                    .then(result => {
                        if (!result.data || result.data.length == 0) {
                            CommonService.presentToast('warning', t("service_management.dms_operation_code_not_found"), t("common.notification_header"))
                        } else if (result.data.length == 1) {
                            updateServiceCodeExtraData(result.data[0])

                        } else {
                            setServiceOperationCodeSearchList(result.data)
                            setVisibleSearchModal(true)
                            setFromServiceCodeExtra(true)
                        }
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        }
    }

    const updateServiceCodeExtraData = (data: ServiceOperationCode) => {
        form.setFieldsValue({
            dmsOperationCodeSearch: ""
        })
        let dmsOperationCode = form.getFieldValue(serviceModel.DmsOperationCode)
        let existedItems = serviceCodeExtraData.filter(x => x.DmsOperationCode == data.DmsOperationCode)
        if (dmsOperationCode == data.DmsOperationCode || existedItems.length > 0) {
            CommonService.presentToast('warning', t("service_management.extra_operation_code_duplicate_message"), t("common.notification_header"))
           
            return
        }
        let extraData: ServiceOperationCodeExtra = {
            DmsOperationCode: data.DmsOperationCode.toUpperCase(),
            ServiceOperationCodeId: props.objectData ? props.objectData.Id : null
        }
        let tempData = serviceCodeExtraData.map(x => Object.assign({}, x))
        tempData.push(extraData)
        setServiceCodeExtraData(tempData)
    }

    const dealershipOnChange = () => {
        setServiceCodeExtraData([])
        if(isProcessDmsRecord()) doGetDmsService()
    }

    useEffect(() => {
        if (!props.objectData) {
            form.setFieldsValue({
                [serviceModel.StatusCode]: Constant.statusCode.Active
            });
        }
        else {
            if (props.objectData.Model) {
                setSelectedModel(props.objectData.Model)
            }
            if (props.objectData.ImageUrl) {
                setImageUrl(props.objectData.ImageUrl)
            }
            if (props.objectData.ServiceOperationCodeExtra) {
                setServiceCodeExtraData(props.objectData.ServiceOperationCodeExtra)
            }


        }
        return () => {
            ServiceOperationCodeService.cancelRequest()
        }
    }, [])

    return (
        <div>
            <Modal
                title={
                    <div>
                        {(!props.objectData || !isProcessDmsRecord()) &&
                            <div className="custom-title">
                                <span>{t("service_management.add_service")}</span>
                            </div>

                        }
                        {props.objectData && isProcessDmsRecord() &&
                            <div className="custom-title">
                                <span>{t("service_management.update_service")}</span>
                                {/* <SyncOutlined onClick={refreshData} /> */}
                            </div>

                        }
                    </div >
                }
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
                className="portal-modal"
                maskClosable={false}

            >
                <Form
                    {...layout}
                    form={form}
                    name="serviceForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    {/* {
                        ((isProcessDmsRecord() && (!props.objectData || props.objectData.Model)) && <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("vehicle_detail.model_code")}
                                    name={["Model", "Code"]} colon={false} labelAlign="left"
                                    className={`dropdown-modal`}
                                >
                                    <Input readOnly={true} onClick={searchModel}
                                        disabled={isLinkedToDmsRecord()}
                                        suffix={canClearModel() ? <CloseOutlined onClick={clearModel} /> : <DownOutlined onClick={searchModel} />} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("vehicle_detail.make")}
                                    name={["Model", "ModelType", "MakeId"]} colon={false} labelAlign="left"
                                >
                                    <Select
                                        disabled={true}>
                                        {
                                            props.makeList.map((n, index) => <Option key={index} value={n.Id}>{n.MakeId}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    label={t("vehicle_detail.model_description")}
                                    name={["Model", "Description"]} colon={false} labelAlign="left"
                                >
                                    <TextArea autoSize={{ minRows: 2, maxRows: 2 }}
                                        disabled={isProcessDmsRecord()} />
                                </Form.Item>
                            </Col>
                        </Row>
                        )
                    } */}

                    <Row>
                        <Col {...firstCols}>
                            <Form.Item
                                label={t("location_management.header_dealership")}
                                name={serviceModel.DealershipId} colon={false} labelAlign="left"
                            >
                                <Select allowClear onChange={() => {dealershipOnChange()}}>
                                    {
                                        dealerships.map((n, index) => <Option key={index} value={n.DealerId}>{n.DealerName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...secondCols} className={isProcessDmsRecord() ? "" : "no-display"}>
                            <Form.Item
                                label={t("service_management.dms_operation_code")}
                                name={serviceModel.DmsOperationCode} colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("service_management.dms_operation_code_required") }]}
                                normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                            >
                                {/* <Search disabled={isLinkedToDmsRecord()} enterButton onSearch={onSearchDmsOperationCode} /> */}
                                <Input className='uppercase-text' maxLength={40} onFocus={setpreviousFormData} onBlur={() => checkAndGetDmsService(serviceModel.DmsOperationCode)} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col {...firstCols}>
                            <Form.Item
                                label={t("service_management.service_code")}
                                name={serviceModel.ServiceCode} colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("service_management.service_code_required") }]}
                                normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col {...secondCols}>
                            <Form.Item
                                label={t("common.status")}
                                name={serviceModel.StatusCode} colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("common.status_required") }]}
                            >
                                <Select>
                                    {
                                        props.statusList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("service_management.service_name")}
                                name={serviceModel.Name} colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("service_management.service_name_required") }]}
                                normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                            >
                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("common.description")}
                                name={serviceModel.Description} colon={false} labelAlign="left"
                                normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                            >
                                <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("vehicle_detail.make")}
                                name={serviceModel.MakeId} colon={false} labelAlign="left"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="value"
                                    filterOption={(input, option) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value={-1}>{t("common.none")}</Option>
                                    {
                                        props.makeList.map((n, index) => <Option key={index} value={n.Id}>{n.MakeId}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("service_management.extra_operation_code")}
                                colon={false} labelAlign="left"
                                name="dmsOperationCodeSearch"
                            >
                                <Search
                                    disabled={!form.getFieldValue(serviceModel.DmsOperationCode)}
                                    allowClear
                                    className='uppercase-text' maxLength={40}
                                    enterButton={t('common.add')}
                                    onSearch={e => searchDmsOperationCode(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            {serviceCodeExtraData && serviceCodeExtraData.length > 0 && <Card className='extra-operation-card'>
                                {
                                    serviceCodeExtraData.map((x, index: number) =>
                                        <span key={index} style={{ display: 'inline-block' }}>
                                            <Tag
                                                closable
                                                onClose={(e: any) => {
                                                    e.preventDefault();
                                                    deleteOperationCode(x);
                                                }}
                                            >
                                                {x.DmsOperationCode}
                                            </Tag>
                                        </span>
                                    )
                                }
                            </Card>
                            }
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("service_management.image_url")}
                                colon={false} labelAlign="left"
                            >
                                <div className='avatar-container'>
                                    <Upload
                                        className="avatar-uploader"
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={beforeUploadPhoto}
                                        accept="image/*"
                                    >
                                        {
                                            imageBase64
                                                ? <img alt={t('service_management.image_url')} src={imageBase64} />
                                                : (
                                                    imageUrl
                                                        ? <img alt={t('service_management.image_url')} src={`${imageUrl}?${(new Date()).toString()}`} />
                                                        : <img alt={t('service_management.image_url')} className="no-image" src={AddImage} />
                                                )
                                        }
                                    </Upload>
                                    {(imageUrl || imageBase64) &&
                                        <span className="remove-logo">
                                            <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" />
                                        </span>
                                    }
                                </div>

                            </Form.Item>
                        </Col>

                    </Row>
                    {/* <Row>

                        <Col {...firstCols}>
                            <Form.Item
                                label={t("service_management.standard_price")}
                                name={serviceModel.StandardPrice} colon={false} labelAlign="left"
                            >
                                <InputNumber min={0} step={0.1} disabled={isProcessDmsRecord()} />
                            </Form.Item>
                        </Col>
                        <Col {...secondCols}>
                            <Form.Item
                                label={t("service_management.standard_hour")}
                                name={serviceModel.StandardHour} colon={false} labelAlign="left"
                            >
                                <InputNumber min={0} step={0.1} disabled={isProcessDmsRecord()} />
                            </Form.Item>
                        </Col>
                    </Row> */}

                </Form>
            </Modal>
            {
                visibleSearchModal &&
                <ServiceSelection handleCancel={closeSearchServiceCode} objectData={serviceOperationCodeSearchList} ></ServiceSelection>
            }
        </div>
    )
}

export default AddUpdateService
