import React, { useState, useEffect } from 'react';
import './portalLayout.scss';
import { Avatar, Button, Dropdown, Layout, Menu, Row, Tag, Col, Select, ConfigProvider } from 'antd';
import { AuthService, Constant, StorageService, LocationManagementService, CommonService } from '../../services/services'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MenuItems from './../../pages/menuItems/menuItems'
import logo from './../../assets/images/logo_horizontal.png';
import { useHistory } from 'react-router-dom';
import UserDropdown from '../../pages/userInfo/userDropdown';
import { useGlobalState } from '../../services/state';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

// const { Header, Sider, Content } = Layout;

const PortalLayout = (props: any) => {
    const { t, i18n } = useTranslation();
    const [collapsed, setCollapsed] = useState(false)
    const [dealerships, setDealerships] = useGlobalState('dealerships');
    const [logoUrl, setLogoUrl] = useGlobalState('logoUrl');
    const [languages] = useGlobalState('languageList');
    const [locations, setLocations] = useGlobalState('locationList');
    const [antLanguage, setAntLanguage] = useGlobalState('antLanguage');
    const [currentLanguage, setCurrentLanguage] = useState<string>();

    let history = useHistory();
    const toggle = () => {
        setCollapsed(!collapsed)
    };

    AuthService.registerIntercepter(props.history)

    useEffect(() => {
        if (!dealerships || dealerships.length == 0) {
            LocationManagementService.getDealerships().then(result => {
                if (result && result.data) {
                    let dealership = CommonService.updateDealershipGlobalName(result.data)
                    setDealerships(dealership)
                }
            }).catch(error => CommonService.handleErrorResponse(error))
        }
        if (!logoUrl) {
            let logoUrlStorage = StorageService.getLogoUrl()
            if (logoUrlStorage) {
                setLogoUrl(logoUrlStorage)
            }
        }
    }, [])

    const onBreakpoint = (broken: boolean) => {
        setCollapsed(broken)
    }

    const getPageDescription = () => {
        if (props.location.pathname == Constant.pages.RoleManagement) {
            return t('portal_menu.role_management')
        }
        else if (props.location.pathname == Constant.pages.UserManagement) {
            return t('portal_menu.user_configuration')
        }
        else if (props.location.pathname == Constant.pages.ServiceManagement) {
            return t('portal_menu.service_management')
        }
        else if (props.location.pathname == Constant.pages.LocationManagement) {
            return t('portal_menu.location_management')
        }
        else if (props.location.pathname == Constant.pages.BookingManagement) {
            return t('portal_menu.booking_management')
        }
    }

    const getDealerId = () => {
        return StorageService.getDealerIdData()
    }

    const getUsernameLogin = () => {
        let authData = StorageService.getAuthData()
        if (authData)
            return authData.name
    }

    const getFirstCharUsername = () => {
        let username = getUsernameLogin()
        if (username)
            return username.substring(0, 1)
    }

    const logout = () => {
        StorageService.removeAuthData()
        setDealerships([])
        history.push(Constant.pages.Login);
    }

    const onLanguageChange = (value: any) => {
        i18n.changeLanguage(value)
        setCurrentLanguage(value)
        StorageService.setLanguage(value)

        let language = Constant.languageCodes.find(l => l.Code == value)
        if (language) {
            setAntLanguage(language.AntdLanguage)
        }

        let newLocation = CommonService.updateLocationDealershipGlobalName(locations, value)
        setLocations(newLocation)

        let newDealership = CommonService.updateDealershipGlobalName(dealerships, value)
        setDealerships(newDealership)
    }

    const userInfo = (
        <Menu className="user-info-dropdown">
            <Menu.Item className="dealer-item">
                <span style={{ marginRight: 8 }}>{t('configuration.dealer_id')}</span>
                <Tag color="default">{getDealerId()}</Tag>
            </Menu.Item>
            <Menu.Item onClick={logout} className="logout-item">
                {t('login_page.logout')}
            </Menu.Item>
        </Menu>
    );

    const getLogoUrl = () => {
        let imageType = logoUrl.substring("data:image/".length, logoUrl.indexOf(";base64"))
        if (imageType)
            return logoUrl
        return `${logoUrl}?${(new Date()).toString()}`
    }
    return (
        <Layout className="portal-layout">
            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 24 }}>
                    <Header>
                        <div className="global-header">
                            <div className="global-header--left-component">
                                <div className="global-header--logo">
                                    <img src={logoUrl ? getLogoUrl() : logo} />
                                </div>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggle,
                                })}
                                <span className="site-layout--breadcrumb">
                                    {getPageDescription()}
                                </span>
                            </div>
                            <div className="global-header--right-component">
                                <div>
                                    {languages && languages.length > 1 &&
                                        <Select value={currentLanguage} dropdownClassName="wrap-text-dropdown uppercase-text" defaultValue={languages[0]} className="w-65 m-l-5 m-r-25 custom-dropdown uppercase-text" onChange={onLanguageChange}>
                                            {
                                                languages.map((n, index) => <Option key={index} title={n} value={n}>{n}</Option>)
                                            }
                                        </Select>
                                    }
                                </div>
                                <Dropdown overlay={<UserDropdown />} trigger={['click']} className="user-info truncate" overlayClassName="user-info-popup">
                                    <div>
                                        <Avatar>{getFirstCharUsername()}</Avatar>
                                        <span className="username">{getUsernameLogin()}</span>
                                    </div>

                                </Dropdown>
                            </div>
                        </div>
                    </Header>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 0 }}>
                    <Header>
                        <div className="global-header">
                            <div className="global-header--left-component">

                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggle,
                                })}
                                <div className="global-header--logo">
                                    <img src={logo} />
                                </div>
                            </div>
                            <div className="global-header--right-component">
                                <Dropdown overlay={<UserDropdown />} className="user-info truncate" overlayClassName="user-info-popup">
                                    <div>
                                        <Avatar>{getFirstCharUsername()}</Avatar>
                                        <span className="username">{getUsernameLogin()}</span>
                                    </div>

                                </Dropdown>
                            </div>
                        </div>
                    </Header>
                </Col>
            </Row>

            <Content>
                <Layout className="site-layout">
                    <Sider trigger={null} collapsible collapsed={collapsed}
                        breakpoint="lg"
                        collapsedWidth="0"
                        width="260"
                        onBreakpoint={(broken) => onBreakpoint(broken)}>
                        <div className="logo" />
                        <MenuItems {...props}></MenuItems>
                    </Sider>
                    <Content>
                        <Row>
                            <Col md={{ span: 24 }} lg={{ span: 0 }} className="m-t-15">
                                <span className="site-layout--breadcrumb m-l-20">
                                    {getPageDescription()}
                                </span>
                            </Col>
                        </Row>
                        <div className="site-layout--content">
                            {
                                props.location.pathname != Constant.pages.EmailTemplateSetup &&
                                <ConfigProvider locale={antLanguage}>
                                    {props.children}
                                </ConfigProvider>
                            }
                            {
                                props.location.pathname == Constant.pages.EmailTemplateSetup &&
                                props.children
                            }
                        </div>
                    </Content>
                </Layout>
            </Content>
        </Layout >
    )
}

export default PortalLayout