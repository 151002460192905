import React, { useState } from 'react';
import logo from './logo.svg';
import '@progress/kendo-theme-default/dist/all.css';
import './App.scss';
import { useTranslation } from 'react-i18next';
import { Constant, ClientService, StorageService, CommonService } from './services/services'
import Routes from './pages/utils/routes';
import LoadingProvider from './pages/utils/loadingCompt/loadingProvider'
import Spinner from './pages/spinner/spinner'
import { useGlobalState } from './services/state';
import { useLocation } from "react-router-dom";
import csc from 'country-state-city'

function App(props: any) {
  const [primaryColor, setPrimaryColor] = useState("#1890ff");
  const [, setCountryStates] = useGlobalState('countryStates');
  const [, setDealerStyle] = useGlobalState('dealerStyle');
  const [makeList, setMakeList] = useGlobalState('makeList');
  const [locations, setLocations] = useGlobalState('locationList');
  const [logoUrl, setLogoUrl] = useGlobalState('logoUrl');
  const [, setLanguages] = useGlobalState('languageList');
  const [antLanguage, setAntLanguage] = useGlobalState('antLanguage');
  const { t, i18n } = useTranslation();

  const location = useLocation();

  React.useEffect(() => {
    ClientService.getDealerStyle()
      .finally()
      .then(result => {
        if (result && result.data) {
          setDealerStyle(result.data)
          setPrimaryColor(result.data.PrimaryColor)
          let logoUrlStorage = StorageService.getLogoUrl()
          if (logoUrlStorage || logoUrlStorage != result.data.AvatarUrl) {
            StorageService.setLogoUrl(result.data.AvatarUrl)
            setLogoUrl(result.data.AvatarUrl)
          }
        }
      })
      .catch(error => {
      });


    const getStatefromApi = process.env.REACT_APP_LOAD_STATE_FROM_API == "true";
    if (getStatefromApi) {
      ClientService.getCountryStates(process.env.REACT_APP_COUNTRY_CODE)
        .then(result => {
          if (result) {
            let states = result.data.map(s => Object.assign({}, { value: s.Description }))
            setCountryStates(states)
          }
        })
        .catch(error => {
          CommonService.handleErrorResponse(error)
        });
    } else {
      const countryCode = process.env.REACT_APP_COUNTRY_CODE ? process.env.REACT_APP_COUNTRY_CODE : '';
      let states = csc.getStatesOfCountry(countryCode).map(s => Object.assign({}, { value: s.name }))
      setCountryStates(states)
    }

    let clientKey = location.search.match("ClientKey=(.*)")
    if (clientKey) {
      StorageService.setClientKey(clientKey[1])
    } else {
      StorageService.removeClientKey()
    }

    if (!makeList || makeList.length == 0) {

      ClientService.getMakesByCountry(process.env.REACT_APP_COUNTRY_CODE)
        // ClientService.getMakes()
        .finally(() => {
          //dismissLoading()
        })
        .then(result => {
          if (result)
            setMakeList(result.data)

        })
        .catch(error => {
          CommonService.handleErrorResponse(error)
        });
    }

    // if (!locations || locations.length == 0) {
    //   ClientService.getLocations()
    //     .then(function (results) {
    //       if (results && results.data) {
    //         let locations = CommonService.updateLocationDealershipGlobalName(results.data)
    //         setLocations(locations)
    //       }
    //     })
    //     .catch(error => {
    //       CommonService.handleErrorResponse(error)
    //     });
    // }

    let languageStr = process.env.REACT_APP_LANGUAGES
    if (languageStr) {
      let availableLanguages = languageStr.split(",")
      if (availableLanguages && availableLanguages.length > 0) {
        setLanguages(availableLanguages)
        i18n.changeLanguage(availableLanguages[0])
        StorageService.setLanguage(availableLanguages[0])

        let language = Constant.languageCodes.find(l => l.Code == availableLanguages[0])
        if (language) {
          setAntLanguage(language.AntdLanguage)
        }
      }
    }
  }, []);

  return (
    <LoadingProvider>
      <Spinner color={primaryColor} />
      <Routes />
    </LoadingProvider>
  );
}

export default App;