import React, { useState } from 'react';
import './roleManagement.scss';
import { Table, Tag, Space, Empty, Button, Row, Col, List, Card, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import {
    Constant,
    ClientService,
    CommonService,
    UserManagementService,
    AlignType
} from '../../services/services'
import { Role, RoleCategory } from '../../models/appModels';
import { PlusOutlined, EditFilled } from '@ant-design/icons'
import AddUpdateRole from './addUpdateRole/addUpdateRole';
import { StatusCell, EditCommandCell, GetTableConfigs } from './../utils/gridColumn/gridColumn'
import LoadingContext from './../utils/loadingCompt/loadingContext'
import InfiniteScroll from 'react-infinite-scroller';

const RoleManagement = (props: any) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [roleData, setRoleData] = useState<Role[]>([])
    const [categoryData, setCategoryData] = useState<RoleCategory[]>([])
    const [totalItems, setTotalItems] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState<Role | null>();
    const [allowModify, setAllowModify] = useState<boolean>(false)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState<any>({
        pageNumber: 1,
        pageSize: Constant.pageSize,
        sort: "",
        isLoadMore: true,
        showLoading: false
    })

    const statusList = [
        {
            Code: Constant.statusCode.Active,
            Description: t('role_management.current')
        },
        {
            Code: Constant.statusCode.Inactive,
            Description: t('role_management.closed')
        }
    ]

    const pageList = [
        {
            Id: Constant.appPageIds.UserManagement,
            Description: t('portal_menu.user_management'),
            AllowAccess: false
        },
        {
            Id: Constant.appPageIds.GeneralSetup,
            Description: t('portal_menu.general_setup'),
            AllowAccess: false
        },
        {
            Id: Constant.appPageIds.LocationManagement,
            Description: t('portal_menu.location_management'),
            AllowAccess: false
        },
        {
            Id: Constant.appPageIds.ServiceManagement,
            Description: t('portal_menu.service_management'),
            AllowAccess: false
        },
        {
            Id: Constant.appPageIds.BookingManagement,
            Description: t('portal_menu.booking_management'),
            AllowAccess: false
        }
    ]
    const getListItemDescription = (appPageRole: any[]) => {
        if (appPageRole.length == pageList.length)
            return t('role_management.all')
        let result = appPageRole.map((item: any) => {
            let selectedPages = pageList.filter((y: any) => y.Id == item.PageId)
            if (selectedPages && selectedPages.length > 0)
                return selectedPages[0].Description
        })
        if (result.length > 0)
            return result.join(", ")
        return ""
    }

    const ItemList = (props: any) => {
        return (
            <span>
                {getListItemDescription(props.data)}
            </span>
        );
    };


    const editData = (data: any) => {
        setSelectedObject(data)
        setVisibleModal(true)
    }

    const getColumns = () => {
        let columns = [
            {
                title: t('role_management.role_id'),
                dataIndex: 'Name',
                key: 'Name',
                sorter: true,
                ellipsis: true
                // sorter: { multiple: 3 }
            },
            {
                title: t('common.description'),
                dataIndex: 'Description',
                key: 'Description',
                sorter: true,
                ellipsis: true
                // sorter: { multiple: 2 }
            },
            {
                title: t('role_management.access'),
                render: (value: any, record: any) => (
                    <ItemList data={record.AppPageRole ? record.AppPageRole : []} />
                ),
                ellipsis: true
            },
            {
                title: t('role_management.status'),
                dataIndex: 'StatusCode',
                key: 'StatusCode',
                sorter: true,
                // sorter: { multiple: 1 },
                render: (value: any, record: any) => (
                    <StatusCell data={record}
                        editFunction={editData}
                        activeStatus={t('role_management.current')}
                        inactiveStatus={t('role_management.closed')} />
                ),
                align: 'center' as AlignType,
                width: 100
            }
        ];
        if (allowModify) {
            columns.push({
                title: t('common.action'),
                dataIndex: 'Id',
                key: 'Id',
                sorter: false,
                render: (value: any, record: any) => (
                    <EditCommandCell data={record} editFunction={editData} />
                ),
                align: 'center' as AlignType,
                width: 80
            })
        }

        return columns
    }
    const getRole = (pageNumber: number, pageSize: number, sort: string, isShowLoading: boolean = false, isScroll: boolean = false) => {
        if (isShowLoading)
            showLoading()
        UserManagementService.getRoles(pageNumber, pageSize, sort)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    if (isScroll) {
                        let list = roleData.map(x => Object.assign({}, x))
                        list = list.concat(result.data.ObjectList)
                        setRoleData(list)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: pageNumber,
                            showLoading: false
                        })
                    }
                    else {
                        setRoleData(result.data.ObjectList)
                    }
                    setTotalItems(result.data.TotalItems)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const getRoleCategory = () => {
        showLoading()
        UserManagementService.getRoleCategories()
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    setCategoryData(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    React.useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.UserManagement))
        getRole(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, true)
        getRoleCategory()
        return () => {
            UserManagementService.cancelRequest()
        }
    }, [])

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        getRole(pagination.current, pagination.pageSize, CommonService.getSortString(sorter))
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        })
    }

    const handleCancel = (objectData?: Role) => {
        setVisibleModal(false)
        if (objectData)
            getRole(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, true)
    };

    const addRole = () => {
        setVisibleModal(true)
        setSelectedObject(null)
    }

    const handleInfiniteOnLoad = (pageNumber: any) => {
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            showLoading: true
        })
        if (roleData.length >= totalItems) {
            setGridConfigOptionsValue({
                ...gridConfigOptions,
                isLoadMore: false,
                showLoading: false
            });
            return;
        }
        getRole(pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, false, true)
    }

    return (
        <div className="role-management">
            {
                allowModify &&
                <div className="action-btn-group">
                    <Button className="primary-btn" icon={<PlusOutlined />} onClick={addRole}>
                        {t("common.add")}
                    </Button>
                </div>
            }
            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(getColumns(), roleData, totalItems, "Id", onTableChange)}
                    />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 0 }}>
                    <div className="infinite-scroll-container">
                        <InfiniteScroll
                            pageStart={1}
                            initialLoad={false}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!gridConfigOptions.showLoading && gridConfigOptions.isLoadMore}
                            useWindow={false}
                        >
                            <List
                                className="card-list"
                                dataSource={roleData}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                                renderItem={(item: Role, i: any) => (
                                    <List.Item>
                                        <Card className="card-selection card-details" key={i}>
                                            <Row className="flex-center">
                                                <Col xs={{ span: 5 }}>
                                                    <label className="title-field">{t("role_management.role_id")}</label>
                                                </Col>
                                                <Col xs={{ span: 17 }}>
                                                    <label className="text-field truncate semi-bold-text">{item.Name}</label>
                                                </Col>
                                                {
                                                    allowModify &&
                                                    <Col xs={{ span: 2 }} className="actions text-right">
                                                        <EditFilled className="action-icon" onClick={() => editData(item)} />
                                                    </Col>
                                                }

                                                <Col xs={{ span: 5 }}>
                                                    <label className="title-field">{t("role_management.access")}</label>
                                                </Col>
                                                <Col xs={{ span: 19 }}>
                                                    <label className="text-field truncate semi-bold-text">{getListItemDescription(item.AppPageRole)}</label>
                                                </Col>
                                                <Col xs={{ span: 19 }}>
                                                    <label className="title-field">{t("common.description")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.Description}</label>
                                                </Col>
                                                <Col xs={{ span: 5 }}>
                                                    <StatusCell data={item}
                                                        editFunction={editData}
                                                        activeStatus={t('role_management.current')}
                                                        inactiveStatus={t('role_management.closed')} />
                                                </Col>

                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                            {gridConfigOptions.showLoading && gridConfigOptions.isLoadMore &&
                                <div className="infinite-scroll-loading-container">
                                    <Spin />
                                </div>
                            }
                        </InfiniteScroll>
                    </div>

                </Col>
            </Row>

            {
                visibleModal &&
                <AddUpdateRole handleCancel={handleCancel} objectData={selectedObject} statusList={statusList} roleCategoryList={categoryData} pageList={pageList} ></AddUpdateRole>
            }
        </div>

    )
}

export default RoleManagement