import React, { useState } from 'react';
import './menuItems.scss';
import { Menu } from 'antd';
import {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    SettingOutlined
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Constant, CommonService } from "./../../services/services"

const { SubMenu } = Menu;

const MenuItems = (props: any) => {
    const { t } = useTranslation();
    return (
        <Menu className="menu-items" mode="inline" defaultSelectedKeys={[props.location.pathname]}>
            {
                CommonService.hasAccessPage(Constant.appPageIds.UserManagement) &&
                <SubMenu key="sub1" icon={<i className='k-i-osb k-i-user-management anticon'></i>} title={t('portal_menu.user_management')}>
                    <Menu.Item key={Constant.pages.RoleManagement}>
                        <span>{t('portal_menu.role_management')}</span>
                        <Link to={Constant.pages.RoleManagement} />
                    </Menu.Item>
                    <Menu.Item key={Constant.pages.UserManagement}>
                        <span>{t('portal_menu.user_configuration')}</span>
                        <Link to={Constant.pages.UserManagement} />
                    </Menu.Item>
                </SubMenu>
            }
            {
                CommonService.hasAccessPage(Constant.appPageIds.GeneralSetup) &&
                <SubMenu key="sub2" icon={<i className='k-i-osb k-i-setting anticon'></i>} title={t('portal_menu.general_setup')}>
                    <Menu.Item key={Constant.pages.GeneralSetup}>
                        <span>{t('portal_menu.general_setup')}</span>
                        <Link to={Constant.pages.GeneralSetup} />
                    </Menu.Item>
                    <Menu.Item key={Constant.pages.ThemeSetup}>
                        <span>{t('portal_menu.theme_setup')}</span>
                        <Link to={Constant.pages.ThemeSetup} />
                    </Menu.Item>
                    <Menu.Item key={Constant.pages.EmailTemplateSetup}>
                        <span>{t('portal_menu.email_template_setup')}</span>
                        <Link to={Constant.pages.EmailTemplateSetup} />
                    </Menu.Item>
                    <Menu.Item key={Constant.pages.SmsTemplateSetup}>
                        <span>{t('portal_menu.sms_template_setup')}</span>
                        <Link to={Constant.pages.SmsTemplateSetup} />
                    </Menu.Item>
                </SubMenu>

            }
            {
                CommonService.hasAccessPage(Constant.appPageIds.LocationManagement) &&
                <Menu.Item key={Constant.pages.LocationManagement}>
                    <i className='k-i-osb k-i-location-management anticon'></i>
                    <span>{t('portal_menu.location_management')}</span>
                    <Link to={Constant.pages.LocationManagement} />
                </Menu.Item>
            }
            {
                CommonService.hasAccessPage(Constant.appPageIds.ServiceManagement) &&
                <Menu.Item key={Constant.pages.ServiceManagement}>
                    <i className='k-i-osb k-i-service-management anticon'></i>
                    <span>{t('portal_menu.service_management')}</span>
                    <Link to={Constant.pages.ServiceManagement} />
                </Menu.Item>
            }
            {
                CommonService.hasAccessPage(Constant.appPageIds.BookingManagement) &&
                <Menu.Item key={Constant.pages.BookingManagement}>
                    <i className='k-i-osb k-i-booking-management anticon'></i>
                    <span>{t('portal_menu.booking_management')}</span>
                    <Link to={Constant.pages.BookingManagement} />
                </Menu.Item>
            }

        </Menu>
    )
}

export default MenuItems