import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Dropdown, Layout, Menu, Row, Tag, Col, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { Constant, StorageService, CommonService } from '../../services/services';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

const UserInfo = (props: any) => {
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState<any | null>()

    const closeModal = () => {
        props.handleCancel()
    }

    useEffect(() => {
        let data = StorageService.getApplicationData()
        if (data) {
            setUserInfo(data.User)
        }
    }, [])

    return (
        <Modal
            visible={true}
            confirmLoading={false}
            className="portal-modal"
            footer={null}
            onCancel={closeModal}
        >
            <Row>
                <Col xs={{ span: 24 }} className="text-center user-info user-info--large-item">
                    <Avatar>{CommonService.getFirstCharUsername(userInfo?.UserName)}</Avatar>
                    <p className="username">{userInfo?.UserName || '_'}</p>
                </Col>
            </Row>
            <Row>
                <Col {...firstCols} className="m-b-10">
                    <label className="title-field">{t("customer_detail.first_name")}</label>
                    <label className="text-field truncate semi-bold-text">{userInfo?.FirstName || '_'}</label>
                </Col>
                <Col {...secondCols} className="m-b-10">
                    <label className="title-field">{t("customer_detail.last_name")}</label>
                    <label className="text-field truncate semi-bold-text">{userInfo?.Surname || '_'}</label>
                </Col>
            </Row>
            <Row>
                <Col {...firstCols} className="m-b-10">
                    <label className="title-field">{t("customer_detail.email")}</label>
                    <label className="text-field truncate semi-bold-text">{userInfo?.Email || '_'}</label>
                </Col>
                <Col {...secondCols} className="m-b-10">
                    <label className="title-field">{t("user_configuration.phone_number")}</label>
                    <label className="text-field truncate semi-bold-text">{userInfo?.PhoneNumber || '_'}</label>
                </Col>
            </Row>

            <Row>
                <Col xs={{ span: 24 }} className="m-b-10">
                    <label className="title-field">{t("user_configuration.roles")}</label>
                    <label className="text-field truncate semi-bold-text">{userInfo?.RoleNames || '_'}</label>
                </Col>
            </Row>

        </Modal>
    )
}

export default UserInfo