import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Empty, Button, Row, Col, List, Card, Input, Form, Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    AlignType,
    ServiceOperationCodeService
} from '../../services/services'
import { Make, ModelType, ServiceOperationCode } from '../../models/appModels';
import { PlusOutlined, EditFilled } from '@ant-design/icons'
import {
    StatusCell,
    EditCommandCell,
    GetColumnTextFilterProps,
    GetColumnStatusFilterProps,
    GetTableConfigs
} from './../utils/gridColumn/gridColumn'
import LoadingContext from './../utils/loadingCompt/loadingContext'
import AddUpdateService from './addUpdateService/addUpdateService'
import InfiniteScroll from 'react-infinite-scroller';
import { constant } from 'underscore';

const ServiceManagement = (props: any) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [serviceData, setServiceData] = useState<ServiceOperationCode[]>([])
    const [totalItems, setTotalItems] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState<ServiceOperationCode | null>();
    const [makeData, setMakeData] = useState<Make[]>([])
    const [allowModify, setAllowModify] = useState<boolean>(false)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState<any>({
        pageNumber: 1,
        pageSize: Constant.pageSize,
        sort: "",
        filters: [],
        isLoadMore: true,
        showLoading: false
    })
    const [modelTypeData, setModelTypeData] = useState<ModelType[]>([])

    const statusList = [
        {
            Code: Constant.statusCode.Active,
            Description: t('common.current')
        },
        {
            Code: Constant.statusCode.Inactive,
            Description: t('common.closed')
        }
    ]

    const editData = (data: any) => {
        setSelectedObject(data)
        setVisibleModal(true)
    }

    const getMakeDataForFilter = () => {
        return makeData.map(x => {
            return {
                text: x.MakeId,
                value: x.Id
            }
        })
    }

    const getColumns = () => {
        let columns = [
            {
                title: t('service_management.service_code'),
                dataIndex: 'ServiceCode',
                key: 'ServiceCode',
                sorter: true,
                ellipsis: true,
                width: 150,
                ...GetColumnTextFilterProps()

            },
            {
                title: t('service_management.service_name'),
                dataIndex: 'Name',
                key: 'Name',
                sorter: true,
                ellipsis: true,
                width: 200,
                ...GetColumnTextFilterProps()

            },
            {
                title: t('service_management.dms_operation_code'),
                dataIndex: 'DmsOperationCode',
                key: 'DmsOperationCode',
                sorter: true,
                ellipsis: true,
                width: 200,
                ...GetColumnTextFilterProps()
            },
            {
                title: t('common.description'),
                dataIndex: 'Description',
                key: 'Description',
                sorter: true,
                ellipsis: true,
                width: 200
            },
            {
                title: t('vehicle_detail.make'),
                dataIndex: 'MakeMakeId',
                key: 'MakeMakeId',
                sorter: true,
                ellipsis: true,
                width: 150,
                filters: getMakeDataForFilter()
            },
            {
                title: t('role_management.status'),
                dataIndex: 'StatusCode',
                key: 'StatusCode',
                sorter: true,
                render: (value: any, record: any) => (
                    <StatusCell data={record}
                        editFunction={editData}
                        activeStatus={t('common.current')}
                        inactiveStatus={t('common.closed')} />
                ),
                align: 'center' as AlignType,
                width: 130,
                ...GetColumnStatusFilterProps({ activeText: t("common.current"), inactiveText: t("common.closed") })
            }
        ];
        if (allowModify) {
            let obj = {
                title: t('common.action'),
                dataIndex: 'Id',
                key: 'Id',
                sorter: true,
                ellipsis: true,
                render: (value: any, record: any) => (
                    <EditCommandCell data={record} editFunction={editData} />
                ),
                align: 'center' as AlignType,
                width: 80
            }
            columns.push(obj)
        }
        return columns
    }

    const addService = () => {
        setVisibleModal(true)
        setSelectedObject(null)
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        let filterList = CommonService.getFilterList(filters)
        getServices(pagination.current, pagination.pageSize, CommonService.getSortString(sorter), filterList)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter),
            filters: filterList
        })
    }

    const getServices = (pageNumber: number, pageSize: number, sort: string, filters: any[], isShowLoading: boolean = false, isScroll: boolean = false) => {
        if (isShowLoading)
            showLoading()
        ServiceOperationCodeService.getServiceOperationCodes(pageNumber, pageSize, sort, filters)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    if (isScroll) {
                        let list = serviceData.map(x => Object.assign({}, x))
                        list = list.concat(result.data.ObjectList)
                        setServiceData(list)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: pageNumber,
                            pageSize: pageSize,
                            showLoading: false
                        })
                    }
                    else {
                        setServiceData(result.data.ObjectList)
                    }
                    setTotalItems(result.data.TotalItems)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const getMakes = () => {
        showLoading()
        ServiceOperationCodeService.getMakesByCountry(process.env.REACT_APP_COUNTRY_CODE)
            .finally(() => {
                dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    setMakeData(result.data)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const handleCancel = (objectData?: ServiceOperationCode) => {
        setVisibleModal(false)
        if (objectData)
            getServices(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
    };

    const getMakeAndModelTypeList = () => {
        showLoading()
        Promise.all([ServiceOperationCodeService.getMakesByCountry(process.env.REACT_APP_COUNTRY_CODE), ServiceOperationCodeService.getModelTypes()])
            .finally(() => {
                dismissLoading()
            })
            .then((values) => {
                if (values[0]) {
                    setMakeData(values[0].data)
                }
                if (values[1]) {
                    setModelTypeData(values[1].data)
                }
            });
    }

    const handleInfiniteOnLoad = (pageNumber: any) => {
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            showLoading: true
        })
        if (serviceData.length >= totalItems) {
            setGridConfigOptionsValue({
                ...gridConfigOptions,
                isLoadMore: false,
                showLoading: false
            });
            return;
        }
        getServices(pageNumber, Constant.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, false, true)
    }

    useEffect(() => {
        getServices(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.ServiceManagement))
        getMakeAndModelTypeList()

        return () => {
            ServiceOperationCodeService.cancelRequest()
        }
    }, [])

    return (
        <div className="service-management">
            {
                allowModify &&
                <div className="action-btn-group">
                    <Button className="primary-btn" icon={<PlusOutlined />} onClick={addService}>
                        {t("common.add")}
                    </Button>
                </div>
            }

            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(getColumns(), serviceData, totalItems, "Id", onTableChange)}
                    />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 0 }}>
                    <div className="infinite-scroll-container">
                        <InfiniteScroll
                            pageStart={1}
                            initialLoad={false}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!gridConfigOptions.showLoading && gridConfigOptions.isLoadMore}
                            useWindow={false}
                        >
                            <List
                                className="card-list"
                                dataSource={serviceData}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                                renderItem={(item: ServiceOperationCode, i: any) => (
                                    <List.Item>
                                        <Card className="card-selection card-details" key={i}>
                                            <Row className="flex-center">
                                                <Col xs={{ span: 8 }} className="p-b-0">
                                                    <label className="title-field truncate">{t("service_management.service_code")}</label>
                                                </Col>
                                                <Col xs={{ span: 14 }}>
                                                    <label className="text-field truncate semi-bold-text">{item.ServiceCode}</label>
                                                </Col>
                                                {
                                                    allowModify &&
                                                    <Col xs={{ span: 2 }} className="actions text-right p-0">
                                                        <EditFilled className="action-icon" onClick={() => editData(item)} />
                                                    </Col>
                                                }

                                                <Col xs={{ span: 24 }}>
                                                    <label className="title-field">{t("service_management.service_name")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.Name}</label>
                                                </Col>

                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("service_management.dms_operation_code")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.DmsOperationCode || '_'}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("vehicle_detail.make")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.MakeMakeId || '_'}</label>
                                                </Col>

                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("service_management.standard_price")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.StandardPrice || '_'}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("service_management.standard_hour")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.StandardHour || '_'}</label>
                                                </Col>

                                                <Col xs={{ span: 19 }}>
                                                    <label className="title-field">{t("common.description")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.Description || '_'}</label>
                                                </Col>
                                                <Col xs={{ span: 5 }}>
                                                    <StatusCell data={item}
                                                        editFunction={editData}
                                                        activeStatus={t('role_management.current')}
                                                        inactiveStatus={t('role_management.closed')} />
                                                </Col>

                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                            {gridConfigOptions.showLoading && gridConfigOptions.isLoadMore &&
                                <div className="infinite-scroll-loading-container">
                                    <Spin />
                                </div>
                            }
                        </InfiniteScroll>
                    </div>

                </Col>
            </Row>
            {
                visibleModal &&
                <AddUpdateService handleCancel={handleCancel} objectData={selectedObject} statusList={statusList} makeList={makeData} dmsIntegrationOn={CommonService.dmsIntegrationOn()} modelTypeList={modelTypeData}></AddUpdateService>
            }
        </div>
    )
}

export default ServiceManagement