import React, { useEffect, useState, forwardRef } from 'react';
import './dealershipSelection.scss';
import { Button, Col, Form, Input, Row, Select, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../services/state';
import { Constant, ClientService, CommonService } from '../../services/services'
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from '../../models/appModels';
import { GoogleMapWrapper } from '../../components/googleMapWrapper/googleMapWrapper'
import LoadingContext from './../utils/loadingCompt/loadingContext'

type LocationState = {
    toPage: string
}

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const btnLayout = {
    xs: { span: 24 },
    md: { span: 0 }
};

const DealershipSelection = forwardRef((props: any, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [locations, setLocations] = useGlobalState('locationList');
    const [serviceDetailData, setServiceDetailData] = useGlobalState('serviceDetailData');
    const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
    const [, setDealershipId] = useGlobalState('dealershipId');
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);

    let history = useHistory();
    let location = useLocation<LocationState>();

    useEffect(() => {
        if (locations && locations.length > 0) {
            setIsLoaded(true)
        } else {
            showLoading()
            ClientService.getLocations()
                .finally(() => {
                    dismissLoading()
                    setIsLoaded(true)
                })
                .then(function (results) {
                    if (results) {
                        let locations = CommonService.updateLocationDealershipGlobalName(results.data)
                        setLocations(locations)

                        if (checkSkipDealershipSelection(results.data)) {
                            skipDealershipSelection(results.data)
                        }
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error)
                });
        }

        if (serviceDetailData && serviceDetailData.location && serviceDetailData.location.Id) {
            form.setFieldsValue({
                LocationId: serviceDetailData.location.Id
            })

            setCurrentLocation(serviceDetailData.location)
        } else {
            calculateLocation()
        }

        if (checkSkipDealershipSelection(locations)) {
            skipDealershipSelection(locations)
        }

    }, [])

    useEffect(() => {
        calculateLocation()
    }, [locations])

    const skipDealershipSelection = (locationList: Location[]) => {
        if (checkSkipDealershipSelection(locationList)) {
            if (locationList[0]) {
                setServiceDetailData({
                    location: locationList[0],
                    serviceAdvisor: null
                })

                setDealershipId(locationList[0].DealershipId)
            }
            history.push(Constant.pages.SelectCustomer);
        }
    }

    const checkSkipDealershipSelection = (locationList: Location[]) => {
        if (locationList && locationList.length == 1) {
            return true
        } else {
            return false
        }
    }

    const calculateLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            if (position && position.coords) {
                let minDistance = -1
                let nearestLocation = null
                for (let i = 0; i < locations.length; i++) {
                    if (locations[i].Latitude && locations[i].Longitude) {
                        let distance = CommonService.getGeoDistance(position.coords.latitude, position.coords.longitude, locations[i].Latitude as number, locations[i].Longitude as number)
                        if (minDistance == -1 || minDistance > distance) {
                            minDistance = distance
                            console.log("Nearesr distance is :", minDistance);
                            nearestLocation = locations[i]
                        }
                    }
                }

                if (nearestLocation) {
                    setCurrentLocation(nearestLocation)
                    form.setFieldsValue({
                        LocationId: nearestLocation.Id
                    })
                }
            }
        });
    }

    const onSubmit = (data: any) => {
        if (data && data.LocationId) {
            let selectedLocation = locations.find(x => x.Id == data.LocationId)
            if (selectedLocation) {
                setServiceDetailData({
                    location: selectedLocation,
                    serviceAdvisor: null
                })

                setDealershipId(selectedLocation.DealershipId)
            }
            history.push(Constant.pages.SelectCustomer);
            // if (location.state && location.state.toPage) {
            //     history.push(location.state.toPage);
            // } else {
            //     history.push(Constant.pages.LandingPage);
            // }
        }
    };

    const onLocationChange = (locationId: number) => {
        if (locationId > 0) {
            let location = locations.find(l => l.Id == locationId)
            if (location) {
                setCurrentLocation(location)
            } else {
                setCurrentLocation(null)
            }
        }
    }

    const getLocationAddress = (location: Location | null) => {
        let address = ""
        if (currentLocation) {
            if (currentLocation.StreetNumber) {
                address += currentLocation.StreetNumber
            }

            if (currentLocation.StreetLine1) {
                if (address.length > 0) {
                    address += ", " + currentLocation.StreetLine1
                } else {
                    address += currentLocation.StreetLine1
                }
            }

            if (currentLocation.StreetLine2) {
                if (address.length > 0) {
                    address += ", " + currentLocation.StreetLine2
                } else {
                    address += currentLocation.StreetLine2
                }
            }

            if (currentLocation.Suburb) {
                if (address.length > 0) {
                    address += ", " + currentLocation.Suburb
                } else {
                    address += currentLocation.Suburb
                }
            }

            if (currentLocation.State) {
                if (address.length > 0) {
                    address += ", " + currentLocation.State
                } else {
                    address += currentLocation.State
                }
            }
        }

        if (address.length == 0) {
            address = "_"
        }

        return address
    }

    const submit = () => {
        form.submit()
    }

    return (
        <div className="dealership-selection-page">
            {isLoaded && <div>
                <p className="app_title text-center m-b-30">{t("dealership_selection.title")}</p>
                <Form
                    {...layout}
                    form={form}
                    name="configForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 0 }} className="m-b-10">
                            <Form.Item
                                label={t("dealership_selection.location")}
                                name="LocationId" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("service_detail.location_required") }]}
                            >
                                <Select showSearch
                                    dropdownClassName="wrap-text-dropdown"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={onLocationChange}
                                >
                                    <Option value="-1">{t("common.none")}</Option>
                                    {
                                        locations.map((n, index) => <Option key={index} title={n.DealershipName + "-" + n.LocationName} value={n.Id}>{n.DealershipName} - {n.LocationName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 15 }} className="m-b-10">
                            <GoogleMapWrapper
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}`}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `600px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                latitude={currentLocation?.Latitude ? currentLocation?.Latitude : -27.2311}
                                longitude={currentLocation?.Longitude ? currentLocation?.Longitude : 153.0060}
                            >

                            </GoogleMapWrapper>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8, offset: 1 }}>
                            <Form.Item
                                className='mobile-hidden'
                                label={t("dealership_selection.location")}
                                name="LocationId" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("service_detail.location_required") }]}
                            >
                                <Select showSearch
                                    dropdownClassName="wrap-text-dropdown"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={onLocationChange}
                                >
                                    <Option value="-1">{t("common.none")}</Option>
                                    {
                                        locations.map((n, index) => <Option key={index} title={n.DealershipName + "-" + n.LocationName} value={n.Id}>{n.DealershipName} - {n.LocationName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Card size="small" title={t("dealership_selection.location_info")}>
                                <Row>
                                    <Col xs={{ span: 24 }}>
                                        <label className="title-field uppercase-text">{t("dealership_selection.address")}</label>
                                        <label className="text-field m-b-10">{CommonService.getLocationAddress(currentLocation)}</label>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <label className="title-field uppercase-text">{t("dealership_selection.phone_number")}</label>
                                        <label className="text-field m-b-10">{currentLocation?.PhoneNumber || '_'}</label>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <label className="title-field uppercase-text">{t("dealership_selection.email")}</label>
                                        <label className="text-field m-b-10">{currentLocation?.Email || '_'}</label>
                                    </Col>
                                </Row>
                            </Card>
                            <Button className="btn full-width m-t-10 uppercase-text mobile-hidden" type="primary" htmlType="submit">
                                {t("common.choose")}
                            </Button>

                        </Col>
                    </Row>
                </Form>
                <Row className='custom-footer'>
                    <Col {...btnLayout}>
                        <Button type="primary" className="btn" onClick={submit}>
                            {t("common.next_label")}
                        </Button>
                    </Col>
                </Row>
            </div>
            }
        </div>
    )
})

export default DealershipSelection