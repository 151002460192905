import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Checkbox, Button, Form, Select, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { Vehicle } from '../../../models/vehicle';
import { Customer, Make, ModalOptions } from '../../../models/appModels';
import { CommonService, CommonService as commonService } from '../../../services/commonService'
import LoadingContext from '../../utils/loadingCompt/loadingContext';
import { ClientService } from '../../../services/clientService';
import { Constant } from '../../../services/constant';
import { useGlobalState } from '../../../services/state';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

export interface VehicleModalOptions extends ModalOptions<Vehicle> {
  makeList: Make[],
  customerDetail: Customer,
  dealershipId?: number,
  ownedVehiclesData: Vehicle[]
}
const vehicleModel = commonService.propToString<Vehicle>()

const AddUpdateVehicle = (props: VehicleModalOptions) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [customerDetailData] = useGlobalState('customerDetailData');
  const { showLoading, dismissLoading } = React.useContext(LoadingContext);
  const [existedVehicle, setExistedVehicle] = useState(false);
  const [vehicleDetail, setVehicleDetail] = useState<Vehicle>();
  const [, setOriginalVehicleMake] = useGlobalState('originalVehicleMake');
  const [customerType] = useGlobalState('customerType');
  let previousFormData: any
  const closeForm = () => {
    props.handleCancel()
  }

  const onSubmit = (vehicle: Vehicle) => {
    if (vehicle.Make) {
      let make = props.makeList.filter(m => m.MakeId == vehicle.Make.MakeId)
      if (make && make.length > 0) {
        vehicle.Make = make[0]
      }
      if (vehicle.Make.Id > 0) {
        vehicle.MakeId = vehicle.Make.Id
      }
    }
    if (vehicle.OdometerReading && !isNaN(parseInt(vehicle.OdometerReading + ""))) {
      vehicle.OdometerReading = parseInt(vehicle.OdometerReading + "");
    }
    if (vehicle.ModelYear && !isNaN(parseInt(vehicle.ModelYear + ""))) {
      vehicle.ModelYear = parseInt(vehicle.ModelYear + "");
    }
    if(customerType === Constant.customerTypes.Organization) {
      vehicle.IsCarOwner = true;
    }
    if (vehicleDetail) {
      Object.assign(vehicleDetail, vehicle)
      props.handleCancel(vehicleDetail)
    } else {
      props.handleCancel(vehicle)
    }
  };

  const setpreviousFormData = () => {
    previousFormData = form.getFieldsValue()
  }

  const checkFieldChange = (fieldName: string, newValue: any) => {
    if (previousFormData) {
      if (previousFormData[fieldName] != newValue) {
        return true
      }
    } else if (newValue) {
      return true
    }

    return false;
  }

  const checkAndGetExistingVehicle = (fieldData: string) => {
    let value = form.getFieldValue(fieldData)
    if (checkFieldChange(fieldData, value)) {
      if (fieldData == vehicleModel.Vin && value && value.length != Constant.requiredVinLength)
        return
      let ownedVehicle = getExistingVehicleInOwnedVehicles(fieldData, value)
      if (ownedVehicle != null) {
        form.setFieldsValue(ownedVehicle)
        setVehicleDetail(ownedVehicle)
        if ((ownedVehicle.Id > 0 || (ownedVehicle.DmsVehicleKey && ownedVehicle.DmsVehicleKey > 0))) {
          setExistedVehicle(true)
        }
      }
      else {
        GetExistingVehicle(value)
      }
    }
  }

  useEffect(() => {
    if (props.objectData) {
      if ((props.objectData.Id > 0 || (props.objectData.DmsVehicleKey && props.objectData.DmsVehicleKey > 0))) {
        setExistedVehicle(true)
      }
      else {
        if (props.objectData.Vin) {
          GetExistingVehicle(props.objectData.Vin)
        }
        else if (props.objectData.RegoNo) {
          GetExistingVehicle(props.objectData.RegoNo)
        }
      }

      setOriginalVehicleMake(props.objectData.MakeId)

      setVehicleDetail(props.objectData)
    }

    return () => {
      ClientService.cancelRequest()
    }
  }, [])

  function GetExistingVehicle(value: any) {
    const values = form.getFieldsValue();
    if (value && props.customerDetail && props.customerDetail.Mobile) {
      showLoading()
      ClientService.searchVehicle(value, props.customerDetail.Mobile, props.dealershipId, customerDetailData.IsOrganisation)
        .finally(() => dismissLoading())
        .then(res => {
          if (res && res.Booking && res.Booking.Vehicle && res.Booking.Vehicle.Id > 0) {
            if(commonService.validateVehicleMakeSearch(res.Booking,props.makeList))
            {
              form.setFieldsValue(res.Booking.Vehicle)
              setVehicleDetail(res.Booking.Vehicle)
              setExistedVehicle(true)
              setOriginalVehicleMake(res.Booking.Vehicle.MakeId)
            }
            else
            {
              form.resetFields();
            }
       
          }
        })
        .catch(error => commonService.handleErrorResponse(error));
    }
  }

  const getExistingVehicleInOwnedVehicles = (fieldData: string, value: any) => {
    if (props.ownedVehiclesData && props.ownedVehiclesData.length > 0) {
      let vehicles = props.ownedVehiclesData
      if (fieldData == vehicleModel.RegoNo) {
        vehicles = vehicles.filter(x => x.RegoNo == value)
      }
      else if (fieldData == vehicleModel.Vin) {
        vehicles = vehicles.filter(x => x.Vin == value)
      }
      if (vehicles.length > 0) {
        return vehicles[0]
      }
    }
    return null

  }

  const isVinDisable = () => {
    if (vehicleDetail && vehicleDetail.Vin && existedVehicle) {
      return true
    }

    return false
  }

  const isRegoDisable = () => {
    if (vehicleDetail && vehicleDetail.RegoNo && existedVehicle) {
      return true
    }

    return false
  }

  const isMakeDisable = () => {
    if (vehicleDetail && vehicleDetail.Make && vehicleDetail.Make.MakeId && existedVehicle) {
      return true
    }
    return false
  }

  return (
    <Modal
      title={!props.objectData ? t("vehicle_detail.add_vehicle") : t("vehicle_detail.edit_vehicle")}
      visible={true}
      onOk={form.submit}
      confirmLoading={false}
      onCancel={closeForm}
      okText={t("common.ok")}
      cancelText={t("common.cancel")}
      className="vehicle-modal"
      maskClosable={false}
    >
      <Form
        {...layout}
        form={form}
        name="vehicleForm"
        onFinish={onSubmit}
        initialValues={props.objectData}
      >
        <Row>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label={t("vehicle_detail.car_registration")}
              name={vehicleModel.RegoNo} colon={false} labelAlign="left"
              rules={[{ required: true, message: t("vehicle_detail.car_registration_required") }]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value) }
            >
              <Input disabled={isRegoDisable()} onFocus={setpreviousFormData} onBlur={() => checkAndGetExistingVehicle(vehicleModel.RegoNo)} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label={t("vehicle_detail.vin")}
              name={vehicleModel.Vin} colon={false} labelAlign="left"
              rules={[
                {
                  required: true,
                  message: t("vehicle_detail.car_registration_or_vin_required")
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value && value.length != Constant.requiredVinLength) {
                      return Promise.reject(t("vehicle_detail.vin_invalid"));
                    }
                    return Promise.resolve();
                  },
                })
              ]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value) }
            >
              <Input disabled={isVinDisable()} onFocus={setpreviousFormData} onBlur={() => checkAndGetExistingVehicle(vehicleModel.Vin)} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label={t("vehicle_detail.make")}
              name={["Make", "MakeId"]} colon={false} labelAlign="left"
              rules={[{ required: true, message: t("vehicle_detail.make_required") }]}
            >
              <Select
                disabled={isMakeDisable()}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {
                  props.makeList.map((n, index) => <Option key={index} value={n.MakeId}>{n.MakeId}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label={t("vehicle_detail.model")}
              name={vehicleModel.Description} colon={false} labelAlign="left"
              normalize={value => CommonService.excludeSpecialCharactersInput(value) }
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label={t("vehicle_detail.year")}
              name={vehicleModel.ModelYear} colon={false} labelAlign="left"
              normalize={value => CommonService.excludeSpecialCharactersInput(value) }
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label={t("vehicle_detail.odometer")}
              name={vehicleModel.OdometerReading} colon={false} labelAlign="left"
              normalize={value => CommonService.excludeSpecialCharactersInput(value) }
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row className='footer-item'>
          <Col xs={24} md={{ span: 24 }}>
            {
              customerType !== Constant.customerTypes.Organization && (
                <Form.Item name={vehicleModel.IsCarOwner.toString()} valuePropName="checked">
                  <Checkbox disabled={customerType === Constant.customerTypes.Organization}>{t("vehicle_detail.car_owner_label")}</Checkbox>
                </Form.Item>
              )
            }
          </Col>
        </Row>
      </Form>
    </Modal>
  )

}

export default AddUpdateVehicle